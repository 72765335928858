var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "report-item-result report-item-result__result-wrapper" },
    _vm._l(_vm.result, function(item, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "report-item-result__result mosgorsud-result"
        },
        [
          _c("app-report-collapse-item", {
            attrs: { opened: _vm.opened },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function() {
                    return [
                      item.billDate
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "report-item-result__result-elem-text-wrapper"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__result-elem-text"
                                },
                                [_vm._v("\n            Дата\n          ")]
                              ),
                              _c("div", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm._f("date")(
                                        item.billDate,
                                        "DD.MM.YYYY"
                                      )
                                    ) +
                                    "\n          "
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      item.amount
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "report-item-result__result-elem-text-wrapper"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__result-elem-text"
                                },
                                [_vm._v("\n            Сумма\n          ")]
                              ),
                              _c("div", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm._f("currency")(item.amount, {
                                        precision: 2
                                      })
                                    ) +
                                    "\n          "
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      item.info.penaltyNameView
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "report-item-result__result-elem-text-wrapper"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__result-elem-text"
                                },
                                [
                                  _vm._v(
                                    "\n            Тип задолженности\n          "
                                  )
                                ]
                              ),
                              _c("div", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.info.penaltyNameView) +
                                    "\n          "
                                )
                              ])
                            ]
                          )
                        : _vm._e()
                    ]
                  },
                  proxy: true
                },
                {
                  key: "content",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        { staticClass: "report-item-result__content-section" },
                        [
                          _c(
                            "div",
                            { staticClass: "report-item-result__content-list" },
                            [
                              item.info.recipientName
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                Получатель\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.info.recipientName) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              item.info.penaltyNameView
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                Тип задолженности\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                item.info.penaltyNameView
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              item.billDate
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                Дата\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm._f("date")(
                                                  item.billDate,
                                                  "DD.MM.YYYY"
                                                )
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              item.amount
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                Сумма\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  item.amount,
                                                  { precision: 2 }
                                                )
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              item.cleaned_info
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                Доп. информация\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.cleaned_info) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              true
            )
          })
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }