
























































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import AppReportItem from 'shared/components/FizCheck/ReportItemModern.vue';
import AppPledgeReportDetails from 'shared/components/FizCheck/Details/PledgeReportDetails.vue';
import AppReportCollapseItem from 'shared/components/FizCheck/ReportCollapseItem.vue';
import AppReportItemGraph from 'shared/components/FizCheck/ReportItemGraph.vue';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
@Component({
  components: {
    AppReportItem,
    AppPledgeReportDetails,
    AppReportCollapseItem,
    AppReportItemGraph,
  },
  props: {
    report: Object,
    status: String,
    images: Array,
    orderIsDone: Boolean,
    missingRequirements: Array,
    openAddFieldDialog: Function,
    updateMap: Function,
    task: Object,
    isPhysical: Boolean,
    onlyOwners: Boolean,
    map: Object,
  },
})
export default class AppBillsReport extends Vue {
  // props
  missingRequirements: any[];
  report: any;
  status: string;
  images: any[];
  orderIsDone: boolean;
  task: any;
  isPhysical: boolean;
  onlyOwners: boolean;
  get = get;
  openAddFieldDialog: (val: string) => any;
  updateMap: (taskName: string, field: string, value: any) => void;
  opened: boolean = false;
  map: any;

  openedLocal: boolean = false;
  resultStatusTrace: any = null;

  created() {
    this.resultStatusTrace = this.resultStatus;
  }

  get statusDone() {
    return this.status === 'done';
  }

  get resultStatus() {
    if (this.task && this.statusDone) {
      if (get(this.task, 'task_result.result.driving_licence.bills.length')) {
        this.updateColor('danger');
        return 'found';
      } else {
        this.updateColor('success');
        return 'not found';
      }
    }
    this.updateColor('info');
    return 'no data';
  }

  get bills() {
    const bills = get(this.task, 'task_result.result.driving_licence.bills');
    const finesBills = bills.filter((bill: any) => bill.serviceCategory && bill.serviceCategory.code === 'FINE');
    return orderBy(finesBills, [ 'billDate' ], [ 'desc' ]);
  }

  get graphItems() {
    const result = this.bills.map(elem => {
      return {
        price: elem.amount,
        date: new Date(elem.billDate).toLocaleDateString('ru-RU'),
      };
    });

    return result;
  }

  toggleOpen() {
    this.openedLocal = !this.openedLocal;
  }

  updateColor(color: string) {
    this.updateMap('bills', 'color', color);
  }
}
