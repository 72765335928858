var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container container_responsive" }, [
    _c("div", { staticClass: "row" }, [
      !_vm.isPartnerRisk
        ? _c("div", { staticClass: "col-lg-3" }, [
            _vm.user
              ? _c(
                  "ul",
                  {
                    staticClass:
                      "list-group list-group-flush shadow-sm user-info-list"
                  },
                  [
                    _vm.isAdmin
                      ? _c(
                          "li",
                          { staticClass: "list-group-item" },
                          [
                            _c(
                              "app-button",
                              {
                                attrs: { block: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.openDialog("order")
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n            Добавить заказ\n          "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.user.id
                      ? _c(
                          "li",
                          { staticClass: "list-group-item text-truncate" },
                          [
                            _c("strong", [_vm._v("Id")]),
                            _c("br"),
                            _c(
                              "span",
                              {
                                staticClass: "link text-truncate",
                                attrs: {
                                  "data-clipboard-text": _vm.user.id,
                                  "data-title": "Id пользователя"
                                }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.user.id) +
                                    "\n          "
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm.user.name
                      ? _c("li", { staticClass: "list-group-item" }, [
                          _c("strong", [_vm._v("Имя")]),
                          _c("br"),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.user.name) +
                              "\n        "
                          )
                        ])
                      : _vm._e(),
                    _vm.user.surname
                      ? _c("li", { staticClass: "list-group-item" }, [
                          _c("strong", [_vm._v("Фамилия")]),
                          _c("br"),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.user.surname) +
                              "\n        "
                          )
                        ])
                      : _vm._e(),
                    _vm.user.username
                      ? _c("li", { staticClass: "list-group-item" }, [
                          _c("strong", [_vm._v("Username")]),
                          _c("br"),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.user.username) +
                              "\n        "
                          )
                        ])
                      : _vm._e(),
                    _c("li", { staticClass: "list-group-item" }, [
                      _c("strong", [_vm._v("Email")]),
                      _c("br"),
                      _c(
                        "div",
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.user.email) +
                              "\n            "
                          ),
                          _c(
                            "app-button",
                            {
                              staticClass: "mr-2 pt-0",
                              attrs: { size: "xs", variant: "light" },
                              on: {
                                click: function($event) {
                                  return _vm.openChangeEmailDialog()
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "fas fa-pencil-alt fa-xs"
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ]),
                    _c(
                      "li",
                      { staticClass: "list-group-item" },
                      [
                        _c("strong", [_vm._v("Телефон")]),
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.user.phone) +
                            "\n          "
                        ),
                        _vm.user.phone
                          ? _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.getWhatsAppLink(_vm.user.phone)
                                }
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticStyle: {
                                      width: "21px",
                                      height: "21px",
                                      "margin-bottom": "4px"
                                    }
                                  },
                                  [
                                    _c("use", {
                                      attrs: { "xlink:href": "#icon-whatsapp" }
                                    })
                                  ]
                                )
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "app-button",
                          {
                            staticClass: "mr-2 pt-0",
                            attrs: { size: "xs", variant: "light" },
                            on: {
                              click: function($event) {
                                return _vm.openChangePhoneDialog()
                              }
                            }
                          },
                          [_c("i", { staticClass: "fas fa-pencil-alt fa-xs" })]
                        )
                      ],
                      1
                    ),
                    _c("li", { staticClass: "list-group-item" }, [
                      _c("strong", [_vm._v("Дата регистрации")]),
                      _c("br"),
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm._f("date")(
                              _vm.user.register_date,
                              "DD.MM.YYYY HH:mm:ss"
                            )
                          ) +
                          "\n        "
                      )
                    ]),
                    _c("li", { staticClass: "list-group-item" }, [
                      _c("strong", [_vm._v("Дата последнего входа")]),
                      _c("br"),
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm._f("date")(
                              _vm.user.last_login_date,
                              "DD.MM.YYYY HH:mm:ss"
                            )
                          ) +
                          "\n        "
                      )
                    ]),
                    _c(
                      "li",
                      { staticClass: "list-group-item" },
                      [
                        _c("strong", [_vm._v("Роль")]),
                        _vm._v(
                          " " +
                            _vm._s(_vm.roles[_vm.user.person_role]) +
                            "\n          "
                        ),
                        _c(
                          "app-button",
                          {
                            staticClass: "mr-2 pt-0",
                            attrs: { size: "xs", variant: "light" },
                            on: {
                              click: function($event) {
                                return _vm.openChooseRoleDialog()
                              }
                            }
                          },
                          [_c("i", { staticClass: "fas fa-pencil-alt fa-xs" })]
                        )
                      ],
                      1
                    ),
                    _c(
                      "li",
                      { staticClass: "list-group-item" },
                      [
                        _c("strong", [_vm._v("Ответственный")]),
                        _vm.userResponsible
                          ? [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.userResponsible.email) +
                                  "\n            "
                              ),
                              _c(
                                "app-button",
                                {
                                  staticClass: "mr-2",
                                  attrs: { size: "xs", variant: "light" },
                                  on: { click: _vm.removeResponsible }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fas fa-trash-alt fa-xs"
                                  })
                                ]
                              )
                            ]
                          : [
                              _c(
                                "app-button",
                                {
                                  attrs: { variant: "link", size: "sm" },
                                  on: {
                                    click: function($event) {
                                      return _vm.openAddResponsibleDialog()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              Выбрать\n            "
                                  )
                                ]
                              )
                            ]
                      ],
                      2
                    ),
                    _c(
                      "li",
                      { staticClass: "list-group-item" },
                      [
                        _c("strong", [_vm._v("Настройки")]),
                        [
                          _c(
                            "app-button",
                            {
                              attrs: { variant: "link", size: "sm" },
                              on: {
                                click: function($event) {
                                  return _vm.openUserSettingsDialog()
                                }
                              }
                            },
                            [_vm._v("\n              Изменить\n            ")]
                          )
                        ]
                      ],
                      2
                    ),
                    _vm.user.comment
                      ? _c("li", { staticClass: "list-group-item" }, [
                          _c("strong", [_vm._v("Comment")]),
                          _c("div", { staticClass: "text-break" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.user.comment) +
                                "\n          "
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm.user.thru_date
                      ? _c("li", { staticClass: "list-group-item" }, [
                          _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n            Аккаунт удален\n            "
                            ),
                            _c("div", { staticClass: "badge badge-danger" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm._f("date")(
                                      _vm.user.thru_date,
                                      "DD.MM.YYYY"
                                    )
                                  ) +
                                  "\n            "
                              )
                            ])
                          ]),
                          _c(
                            "div",
                            [
                              _c(
                                "app-button",
                                {
                                  staticClass: "mt-2",
                                  attrs: {
                                    variant: "primary",
                                    loading: _vm.btnLoading
                                  },
                                  on: { click: _vm.recoverAccount }
                                },
                                [
                                  _vm._v(
                                    "\n              Восстановить\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      : _vm._e()
                  ]
                )
              : _vm._e()
          ])
        : _vm._e(),
      _c("div", { staticClass: "col-lg-9" }, [
        _c(
          "div",
          { staticClass: "bg-white shadow-sm" },
          [
            !_vm.isPartnerRisk
              ? _c("div", { staticClass: "nav-tabs-wrap" }, [
                  _c(
                    "ul",
                    {
                      staticClass: "nav nav-tabs nav-tabs_sm nav-tabs_underline"
                    },
                    [
                      _c(
                        "li",
                        { staticClass: "nav-item" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "nav-link",
                              attrs: {
                                "active-class": "active",
                                exact: "",
                                to: {
                                  name: "admin-user-orders",
                                  params: { userID: _vm.userID }
                                }
                              }
                            },
                            [_vm._v("\n                Заказы\n              ")]
                          )
                        ],
                        1
                      ),
                      _vm.isAdmin
                        ? _c(
                            "li",
                            { staticClass: "nav-item" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "nav-link",
                                  attrs: {
                                    "active-class": "active",
                                    exact: "",
                                    to: {
                                      name: "admin-user-orders-new",
                                      params: { userID: _vm.userID }
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Заказы V2\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isAdmin
                        ? _c(
                            "li",
                            { staticClass: "nav-item" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "nav-link",
                                  attrs: {
                                    "active-class": "active",
                                    to: {
                                      name: "admin-user-emails",
                                      params: { userID: _vm.userID }
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Emails\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isAdmin
                        ? _c(
                            "li",
                            { staticClass: "nav-item" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "nav-link",
                                  attrs: {
                                    "active-class": "active",
                                    to: {
                                      name: "admin-user-finance",
                                      params: { userID: _vm.userID }
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Финансы\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isAdmin || _vm.isManager
                        ? _c(
                            "li",
                            { staticClass: "nav-item" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "nav-link",
                                  attrs: {
                                    "active-class": "active",
                                    to: {
                                      name: "admin-user-price-component",
                                      params: { userID: _vm.userID }
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Тариф\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isAdmin
                        ? _c(
                            "li",
                            { staticClass: "nav-item" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "nav-link",
                                  attrs: {
                                    "active-class": "active",
                                    to: {
                                      name: "admin-user-tariff-tools",
                                      params: { userID: _vm.userID }
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Партнерка\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isAdmin
                        ? _c(
                            "li",
                            { staticClass: "nav-item" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "nav-link",
                                  attrs: {
                                    "active-class": "active",
                                    to: {
                                      name: "admin-user-bills",
                                      params: { userID: _vm.userID }
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Счета\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isAdmin
                        ? _c(
                            "li",
                            { staticClass: "nav-item" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "nav-link",
                                  attrs: {
                                    "active-class": "active",
                                    to: {
                                      name: "admin-user-metriks",
                                      params: { userID: _vm.userID }
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Метрики\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isAdmin
                        ? _c(
                            "li",
                            { staticClass: "nav-item" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "nav-link",
                                  attrs: {
                                    "active-class": "active",
                                    exact: "",
                                    to: {
                                      name: "admin-user-logs",
                                      params: { userID: _vm.userID }
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Логи\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                ])
              : _vm._e(),
            _c("router-view")
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }