import Vue from 'vue';
import Component from 'vue-class-component';
import { Provide } from 'vue-property-decorator';
import clone from 'lodash/clone';
import isEmpty from 'lodash/isEmpty';
import mergeWith from 'lodash/mergeWith';
import keyBy from 'lodash/keyBy';
import get from 'lodash/get';
import { mapState } from 'vuex';

import AppAttachmentsDialog from 'shared/components/Risk/AttachmentsDialog.vue';
import AppStatementNotesDialog from '@/views/Admin/AdminUserInfo/RiskReport/StatementNotesDialog.vue';
import AppUpdateReportDialog from './UpdateReportDialog.vue';
import AppRiskReportForm from './RiskReportForm/RiskReportForm.vue';
import AppRiskReportPdfPreview from '@/views/Admin/AdminUserInfo/RiskReportV2/RiskReportPdfPreview.vue';
import AppEgrnObjectPdfPreview from '@/views/Admin/PdfPreview/EgrnObjectPdfPreview.vue';
import AppRightListPdfPreview from '@/views/Admin/PdfPreview/RightListPdfPreview.vue';
import AppRiskAddConclusion from '@/views/Admin/AdminUserInfo/RiskReportV2/RiskAddConclusion.vue';
import AppRiskUpdateScraperStatusDialog from './RiskUpdateScraperStatus.vue';

import { ObjectTypes } from 'shared/store/modules/objectDetail/types';
import { statusText } from 'shared/utils/orderStatus';

interface OwnerItem {
  ownerId: string;
  owner: OwnerRequestBody;
  api: any;
}

@Component({
  components: { AppRiskReportForm },
  props: {
    userID: String,
    orderItemID: String,
    encumbrances: Boolean,
  },
  computed: {
    ...mapState('objectDetail', [ 'riskReportV2' ]),
  },
})
export default class AppRiskReport extends Vue {
  // props
  userID!: string;
  orderItemID!: string;
  encumbrances!: boolean;

  // data()
  loading: boolean = true;
  reportSending: boolean = false;
  orderItem: any = null;
  report: RiskReport = null;
  kadastrNumber: string = '';
  objectInfo: any = null;
  attachments: AttachmentItem[] = [];
  owners: LawInformationOwner[] = [];
  order: AdminOrderInfo = null;
  reportV2: LawInformation;
  riskReportV2: LawInformation;
  landCadInfo: RealtyObject = null;

  @Provide()
  Report = { ownersById: {} };

  get products(): { [key: string]: Product } {
    return this.$store.state.productsByKey;
  }

  // TODO: надо получить эти notes из report
  get statementNotes(): { right: string; egrn: string } {
    const egrn = '';
    const right = '';

    return { egrn, right };
  }

  get statusText() {
    if (this.riskReportV2) {
      return statusText(this.riskReportV2.order.status);
    }
  }

  get productName() {
    if (!this.riskReportV2) {
      return '';
    }
    return this.riskReportV2.product_name;
  }

  get isRisk() {
    return this.productName === 'RiskAssessmentV2';
  }

  get isEgrnObject() {
    return /EgrnObject/.test(this.productName);
  }

  get isEgrnRightList() {
    return /EgrnRightList/.test(this.productName);
  }

  get pdfTask() {
    if (this.riskReportV2) {
      let task;
      if (this.riskReportV2.tasks_general) {
        task = this.riskReportV2.tasks_general.find((elem) => elem.task_type === 'rc-risk-pdf');
      }
      if (!task && this.riskReportV2.owners && this.riskReportV2.owners.length) {
        task = this.riskReportV2.owners[0].tasks.find((elem) => elem.task_type === 'rc-risk-pdf');
      }
      return task;
    }
  }

  get riskOrderItem() {
    if (this.order) {
      return this.order.OrderItem.find((elem) => {
        return /RiskAssessment/.test(elem.ProductName) || /CheckOwner/.test(elem.ProductName);
      });
    }
  }

  get statements(): StatementItems {
    // const egrn = this.getPurchasedProduct('EgrnObject');
    // const rightList = this.getPurchasedProduct('EgrnRightList');
    // const risk = this.getPurchasedProduct('RiskAssessment');
    const result = {
      egrn: this.mapToStatementItem(),
      rightList: this.mapToStatementItem(),
      risk: this.mapToStatementItem(),
    };
    if (this.riskReportV2 && this.riskReportV2.tasks_parse_xml) {
      const newEgrn = this.riskReportV2.tasks_parse_xml.find((elem) => {
        if (elem) {
          return elem.task_type === 'download_service_egrn_object_parse';
        }
      });
      const newRightList = this.riskReportV2.tasks_parse_xml.find((elem) => {
        if (elem) {
          return elem.task_type === 'download_service_egrn_right_list_parse';
        }
      });
      if (!isEmpty(newEgrn) && (typeof newEgrn.task_result === 'object')) {
        if (!result.egrn.data) {
          result.egrn.data = newEgrn.task_result;
        } else {
          result.egrn.data.parcel = newEgrn.task_result.parcel;
          result.egrn.data.extract_object = newEgrn.task_result.extract_object;
          result.egrn.data.generic_record = newEgrn.task_result.generic_record;
          result.egrn.data.extracted_at = newEgrn.task_result.extracted_at;
          result.egrn.data.object_info = newEgrn.task_result.object_info;
        }
        result.egrn.isCompleted = get(newEgrn, 'status') === 'done';
      }
      if (!isEmpty(newRightList)) {
        result.rightList.data = newRightList.task_result;
        result.rightList.isCompleted = get(newRightList, 'status') === 'done';
      }
    }
    return result;
  }

  get landCadNumber() {
    return get(this.statements, 'egrn.data.generic_record.cad_links.land_cad_numbers.land_cad_number.cad_number');
  }

  // lifecycle hooks
  async created() {
    try {
      // await Promise.all([this.handleRequest(), this.getReportInfo(), this.getAttachments()]);
      await Promise.all([ this.getReportInfo(), this.getAttachments() ]);
      if (this.objectInfo) {
        const objectInfoFull = await this.$api.object.InfoFull(this.objectInfo.Number);
        this.objectInfo = mergeWith(
          clone(this.objectInfo),
          objectInfoFull,
          (infoValue, fullInfoValue, key) => {
            if (key === 'UpdatedAt') {
              return fullInfoValue;
            }

            if (infoValue && !fullInfoValue) {
              return infoValue;
            }

            return fullInfoValue;
          },
        );
      }
      const [ order ] = await this.$api.admin.Orders<AdminOrderInfo[]>({
        userID: this.userID,
        orderID: this.riskReportV2.order.input_data.order_id,
      });
      this.order = order;
    } catch (error) {
      console.error(error);
    }

    this.loading = false;
  }

  // methods

  async getLandInfo() {
    let fullInfo = await this.$api.object.InfoFull<RealtyObject>(this.landCadNumber, { timeout: 4e4, params: { source: '2', useCache: 'no', keyword: 'please' } });
    if (isEmpty(fullInfo) || !fullInfo.Area) {
      fullInfo = await this.$api.object.InfoFull<RealtyObject>(this.landCadNumber, { timeout: 4e4 });
    }
    this.landCadInfo = fullInfo;
  }

  mapToStatementItem(): StatementItem {
    return {
      isPurchased: true,
      isCompleted: true,
      product: null,
      data: null,
    };
  }

  addOwner() {
    const owner = {
      owner_type: 0,
      first: '',
      surname: '',
      patronymic: '',
      birthday: '',
      region: '',
      passport: '',
      registration_number: '',
      inn: '',
    };

    this.owners.push(owner);
  }

  // async handleRequest() {
  //   const statementsData = await this.$api.risk.GetReportStatementsData(this.orderItemID);
  //   // const [ order ] = await this.$api.admin.Orders<AdminOrderInfo[]>({ orderID: statementsData.order_item.OrderID });
  //   const orderItem: RiskReportOrderItem = statementsData.order_item;
  //   const meta: RiskReportItemMetadata =
  //     orderItem && orderItem.ItemMetadata ? orderItem.ItemMetadata : {};

  //   this.objectInfo = _.clone(statementsData.object_info_full);

  //   if (this.objectInfo && _.isEmpty(this.objectInfo.Number)) {
  //     this.objectInfo.Number = meta.kadastr_number;
  //   }

  //   this.orderItem = statementsData.order_item;
  //   this.kadastrNumber = meta.kadastr_number;
  // }

  async getReportInfo() {
    let data: any = null;
    await this.$store.dispatch(ObjectTypes.actions.GET_RISK_REPORT_V2, this.orderItemID);
    const reportV2 = this.riskReportV2;
    console.log('getReportV2', reportV2);
    this.kadastrNumber = this.riskReportV2.order.input_data.kad_number;
    if (this.kadastrNumber) {
      this.objectInfo = await this.$api.object.InfoFull<RealtyObject>(this.kadastrNumber);
    }
    if (isEmpty(reportV2)) {
      data = await this.$api.risk.GetReport(this.orderItemID);
    } else {
      this.report = data;
      this.reportV2 = reportV2;

      if (this.reportV2.tasks_general) {
        const objectInfoTask = this.reportV2.tasks_general.find((elem) => {
          return elem.task_type === 'get_object_full';
        });
        if (objectInfoTask && objectInfoTask.task_result && (typeof objectInfoTask.task_result === 'object')) {
          this.objectInfo = objectInfoTask.task_result;
        }
      }

      if (!isEmpty(reportV2.owners)) {
        this.Report.ownersById = keyBy(reportV2.owners, (elem) => elem.owner.owner_id);

        this.owners = reportV2.owners.map((item: any): any => {
          return item.owner;
        });
      }
    }
  }

  async getAttachments() {
    try {
      const attachments = await this.$api.upload.GetAttachmentList(this.orderItemID);
      this.attachments = attachments;
    } catch (error) {
      console.error(error);
    }
  }

  async CreateOwner(owner: LawInformationOwner) {
    try {
      const body = {
        ...owner,
        order_item_id: this.orderItemID,
      };
      console.log(body, 'createOwner');
      if (owner.birthday) {
        body.birthday = new Date(owner.birthday).toISOString();
      } else {
        body.birthday = null;
      }
      await this.$api.risk.AddOwnerV2([ body ]);
    } catch (error) {
      console.error(error);
      this.$noty.error({ text: this.$api.getErrorMessage(error) });
    }

    this.getReportInfo();
  }

  ownerItemUpdated() {
    this.getReportInfo();
  }

  removeReportItem(id: string, forceUpdate?: boolean) {
    this.owners = this.owners.filter(item => item.owner_id !== id);

    if (forceUpdate) {
      this.getReportInfo();
    }
  }

  async createPdf() {
    const showOnlyUser = this.riskReportV2.order.input_data.product_name === 'CheckOwnerV2';
    let riskInsurance = false;
    if (this.orderItem &&
      this.orderItem.metadata &&
      this.orderItem.metadata.riskInsurance) {
      riskInsurance = true;
    }

    if (this.landCadNumber) {
      await this.getLandInfo();
    }

    this.$dialog.open({
      component: AppRiskReportPdfPreview,
      props: {
        riskInsurance,
        landCadInfo: this.landCadInfo,
        report: this.reportV2,
        objectInfo: this.objectInfo,
        onlyUser: showOnlyUser,
        objectKey: showOnlyUser ? '' : this.riskReportV2.order.input_data.kad_number,
        statements: this.statements,
      },
    });
  }

  egrnPreview() {
    this.$dialog.open({
      component: AppEgrnObjectPdfPreview,
      props: {
        landCadInfo: this.landCadInfo,
        report: this.reportV2,
        objectInfo: this.objectInfo,
        objectKey: this.riskReportV2.order.input_data.kad_number,
        statements: this.statements,
      },
    });
  }

  rightListPreview() {
    this.$dialog.open({
      component: AppRightListPdfPreview,
      props: {
        landCadInfo: this.landCadInfo,
        report: this.reportV2,
        objectInfo: this.objectInfo,
        objectKey: this.riskReportV2.order.input_data.kad_number,
        statements: this.statements,
      },
    });
  }

  async sendReportToUser() {
    if (!confirm('Вы уверены что хотите отправить отчет пользователю?')) {
      return;
    }

    try {
      this.reportSending = true;
      await this.$api.risk.FinishReportV2(this.orderItemID);
      this.$noty.success({ text: 'Отчет отправлен' });
    } catch (error) {
      console.error(error);
      this.$noty.error({ text: 'Ошибка при отправке' });
    }

    this.reportSending = false;
  }

  showConclusion() {
    if (!this.riskReportV2) {
      return;
    }

    this.$dialog.open({
      component: AppRiskAddConclusion,
      props: {
        report: this.riskReportV2,
        onChange: () => this.getReportInfo(),
      },
    });
  }

  showAttachments() {
    this.$dialog.open({
      component: AppAttachmentsDialog,
      props: { attachments: this.attachments },
    });
  }

  showNotes() {
    this.$dialog.open({
      component: AppStatementNotesDialog,
      props: {
        notes: this.statementNotes,
      },
    });
  }

  goToOrder() {
    this.$router.push({
      name: 'admin-order-detail',
      params: {
        orderID: this.riskReportV2.order.input_data.order_id,
        userID: this.userID,
      },
    });
  }

  openUpdateReportDialog() {
    this.$dialog.open({
      component: AppUpdateReportDialog,
      props: {
        onSubmit: this.updateReport,
        newRiskId: this.orderItemID,
        egrnId: this.reportV2.order.input_data.egrn_object_id,
        rightListId: this.reportV2.order.input_data.egrn_right_list_id,
      },
    });
  }

  async updateReport(orderRiskID: string, orderEgrnID: string, orderRightListID: string) {
    try {
      await this.$api.risk.UpdateReportV2({
        order_item_id: orderRiskID,
        input_data: {
          egrn_object_id: orderEgrnID,
          egrn_right_list_id: orderRightListID,
        },
      });
      this.$noty.success({ text: 'Отчет обновлён' });
    } catch (error) {
      console.error(error);
      if (error.response.data.errorTxt === 'Invalid request') {
        this.$noty.error({ text: 'Ошибка в номере заказа' });
      } else {
        this.$noty.error({ text: 'Ошибка при обновлении' });
      }
    }
  }

  async restartPdfTask() {
    if (!this.pdfTask) {
      return;
    }
    this.loading = true;
    try {
      await this.$api.risk.RestartTaskV2({
        task_id: this.pdfTask.task_id,
        order_item_id: this.orderItemID,
      });

      this.$noty.info({ text: 'Парсер будет перезапущен' });
    } catch (error) {
      console.error(error);
      this.$noty.error({ text: this.$api._getErrorMessage(error) });
    }

    this.loading = false;
  }

  showUpdateStatus() {
    if (!this.riskReportV2.tasks_parse_xml) {
      this.riskReportV2.tasks_parse_xml = [];
    }
    if (!this.riskReportV2.tasks_general) {
      this.riskReportV2.tasks_general = [];
    }
    const reportItem = {
      tasks: [ ...this.riskReportV2.tasks_parse_xml, ...this.riskReportV2.tasks_general ],
    };
    this.$dialog.open({
      component: AppRiskUpdateScraperStatusDialog,
      props: {
        report: reportItem,
        orderItemId: this.riskReportV2.order.order_item_id,
      },
    });
  }
}
