var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isPhysical
    ? _c(
        "app-report-item",
        {
          attrs: {
            title: "Налоговые задолженности",
            icon: "coins",
            status: _vm.taskStatus,
            images: _vm.images,
            "missing-requirements": _vm.missingRequirements,
            color: _vm.map.color,
            opened: _vm.resultStatus === "found",
            source: {
              domain: "nalogi.online",
              link: "https://nalogi.online/"
            }
          },
          scopedSlots: _vm._u(
            [
              {
                key: "not-required",
                fn: function() {
                  return [
                    !_vm.inn && !_vm.$isServer
                      ? _c(
                          "span",
                          {
                            staticClass: "link font-weight-500 d-print-none",
                            on: {
                              click: function($event) {
                                return _vm.openAddFieldDialog("inn")
                              }
                            }
                          },
                          [_vm._v("\n      Добавить\n    ")]
                        )
                      : _vm._e()
                  ]
                },
                proxy: true
              },
              _vm.resultStatus === "found"
                ? {
                    key: "extra-content",
                    fn: function() {
                      return [
                        _vm.result.length > 1
                          ? _c(
                              "div",
                              { staticClass: "text-right report-open-all" },
                              [
                                !_vm.$isServer
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "link",
                                        on: {
                                          click: function($event) {
                                            _vm.opened = !_vm.opened
                                          }
                                        }
                                      },
                                      [
                                        !_vm.opened
                                          ? [
                                              _vm._v(
                                                "\n          Раскрыть все\n        "
                                              )
                                            ]
                                          : [
                                              _vm._v(
                                                "\n          Скрыть все\n        "
                                              )
                                            ]
                                      ],
                                      2
                                    )
                                  : _vm._e()
                              ]
                            )
                          : _vm._e(),
                        _c("app-tax-report-details", {
                          attrs: {
                            opened: _vm.opened || _vm.isPdf,
                            result: _vm.$isServer
                              ? _vm.slicedResult
                              : _vm.result,
                            date: _vm.task.in_progress_started_time
                          }
                        }),
                        _vm.slicedResult.length < _vm.result.length &&
                        _vm.$isServer
                          ? _c(
                              "div",
                              { staticClass: "rc-modern-info-panel mt-3" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "rc-modern-info-panel__wrapper"
                                  },
                                  [
                                    _c("div", [_c("app-warning-img")], 1),
                                    _c("div", [
                                      _vm._v(
                                        "\n          Отображено " +
                                          _vm._s(_vm.slicedResult.length) +
                                          " задолженностей из " +
                                          _vm._s(_vm.result.length) +
                                          ", полная информация доступна в веб версии отчета.\n        "
                                      )
                                    ])
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  }
                : null
            ],
            null,
            true
          )
        },
        [
          _vm.task
            ? _c(
                "div",
                { staticClass: "report-item-modern__subtitle-wrapper" },
                [
                  _vm.resultStatus === "found"
                    ? [
                        _c(
                          "div",
                          { staticClass: "report-item-modern__subtitle" },
                          [
                            _vm._v(
                              "\n        Найдено задолженностей:\n        " +
                                _vm._s(_vm.result.length) +
                                " на\n        " +
                                _vm._s(
                                  _vm._f("currency")(_vm.penaltySum, {
                                    precision: 2
                                  })
                                ) +
                                "\n      "
                            )
                          ]
                        )
                      ]
                    : [
                        _c(
                          "div",
                          { staticClass: "report-item-modern__subtitle" },
                          [
                            _vm._v(
                              "\n        Задолженности отсутствуют\n      "
                            )
                          ]
                        )
                      ]
                ],
                2
              )
            : _vm.orderIsDone
            ? [_vm._v("\n    Недостаточно данных\n  ")]
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }