

























































import Vue from 'vue';
import Component from 'vue-class-component';
import AppStatementItem from './StatementItem.vue';

@Component({
  components: { AppStatementItem },
  props: {
    showPayInfo: Boolean,
    opened: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: '',
    },
    payed: Boolean,
    images: {
      type: Array,
      default: false,
    },
  },
})
export default class AppStatementObjectPlan extends Vue {
  // props
  showPayInfo!: boolean;
  opened: boolean;
  images: any[];
  payed: boolean;
  status: string;

  get hasImages() {
    return this.images && !!this.images.length;
  }
}
