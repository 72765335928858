















































































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';

import AppRosreestrInfo from 'shared/components/RiskNew/RosreestrInfo.vue';
import AppRisk from 'shared/components/RiskNew/Risk.vue';
import AppStatementEncumbrance from 'shared/components/RiskNew/Statement/StatementEncumbrance.vue';
import AppStatementRestrictions from 'shared/components/RiskNew/Statement/StatementRestrictions.vue';
import AppStatementOwners from 'shared/components/RiskNew/Statement/StatementOwners.vue';
import AppStatementOwnersHistory from 'shared/components/RiskNew/Statement/StatementOwnersHistory.vue';
import AppLogoSvg from 'shared/components/Static/logo-new.vue';
import AppEcpSvg from 'shared/components/Static/ecp-print.vue';
import AppLogoNewAlt from 'shared/components/Static/logo-new-alt.vue';
import AppStatementObjectPlan from 'shared/components/RiskNew/Statement/StatementObjectPlan.vue';
import AppStatementLandInfo from 'shared/components/RiskNew/Statement/StatementLandInfo.vue';

@Component({
  name: 'AppRiskPdf',
  components: {
    AppRosreestrInfo,
    AppRisk,
    AppStatementEncumbrance,
    AppStatementRestrictions,
    AppStatementOwners,
    AppStatementOwnersHistory,
    AppLogoSvg,
    AppEcpSvg,
    AppLogoNewAlt,
    AppStatementObjectPlan,
    AppStatementLandInfo,
  },
  props: {
    info: Object,
    landCadInfo: Object,
    statements: Object,
    objectKey: String,
    fetchRisk: Function,
    riskReport: Object,
    onlyOwners: Boolean,
    renderType: {
      type: String,
      default: '',
    },
    riskInsurance: {
      type: Boolean,
      default: false,
    },
  },
})
export default class AppRiskPdf extends Vue {
  // props
  info: RealtyObject;
  landCadInfo: RealtyObject;
  statements: StatementItems;
  objectKey: string;
  riskReport!: LawInformation;
  onlyOwners: boolean;
  renderType: string;
  fetchRisk: (orderId: string) => void;

  get settings() {
    if (this.riskReport.order && this.riskReport.order.input_data && this.riskReport.order.input_data.person_settings) {
      return this.riskReport.order.input_data.person_settings.data;
    } else return null;
  }

  get haveLogo() {
    const logoPerm = this.getSetting('add_logo_permission');
    const logo = this.getSetting('company_logo_url');
    if (
      logoPerm &&
      (logoPerm.value === 'active') &&
      logo &&
      logo.value) {
      return logo.value;
    } else return null;
  }

  get companyName() {
    const companyName = this.getSetting('company_name');
    if (
      this.haveLogo &&
      companyName &&
      companyName.value) {
      return companyName.value;
    } else return null;
  }

  get egrnTask() {
    if (this.riskReport && this.riskReport.tasks_parse_xml) {
      return this.riskReport.tasks_parse_xml.find((elem) => {
        if (elem) {
          return elem.task_type === 'download_service_egrn_object_parse';
        }
      });
    }
    return null;
  }

  get objectGeneric() {
    if (this.egrnTask && this.egrnTask.task_result) {
      return this.egrnTask.task_result.generic_record;
    }
    return null;
  }

  get objectPlan() {
    if (this.egrnTask && this.egrnTask.meta) {
      return this.egrnTask.meta.images;
    }
    return [];
  }

  get hideEcp() {
    const hideEcp = this.getSetting('hide_ecp');
    return hideEcp && (hideEcp.value === 'active');
  }

  getSetting(key: string) {
    if (this.settings && this.settings.length) {
      return this.settings.find((elem: any) => {
        return elem.key === key;
      });
    }
  }
}
