var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "report-item report-item-modern" },
    [
      _c(
        "app-collapse",
        [
          _c(
            "app-collapse-item",
            {
              staticClass: "fizcheck-report-collapse",
              attrs: { opened: _vm.opened, disabled: _vm.disabled }
            },
            [
              _c("div", { attrs: { slot: "title" }, slot: "title" }, [
                _c("div", { staticClass: "row no-gutters flex-nowrap" }, [
                  _vm.externalIcon
                    ? _c("img", {
                        staticClass:
                          "report-item__icon report-item-modern__icon col-auto mr-3 d-none d-md-block",
                        class:
                          _vm.iconStyle +
                          " report-item-modern__icon--" +
                          _vm.color,
                        attrs: { src: "/rc-modern/" + _vm.icon }
                      })
                    : _c("i", {
                        staticClass:
                          "report-item__icon report-item-modern__icon col-auto fa-fw mr-3 mt-1 d-none d-md-block",
                        class:
                          _vm.iconStyle +
                          " fa-" +
                          _vm.icon +
                          " report-item-modern__icon--" +
                          _vm.color
                      }),
                  _c("div", { staticClass: "report-item-modern__body col" }, [
                    _c(
                      "div",
                      {
                        staticClass: "report-item-modern__head row no-gutters"
                      },
                      [
                        _vm.externalIcon
                          ? _c("img", {
                              staticClass:
                                "report-item__icon report-item-modern__icon col-auto mr-3 d-md-none",
                              class:
                                _vm.iconStyle +
                                " report-item-modern__icon--" +
                                _vm.color,
                              attrs: { src: "/rc-modern/" + _vm.icon }
                            })
                          : _c("i", {
                              staticClass:
                                "report-item__icon report-item-modern__icon col-auto fa-fw mr-3 d-md-none",
                              class:
                                _vm.iconStyle +
                                " fa-" +
                                _vm.icon +
                                " report-item-modern__icon--" +
                                _vm.color
                            }),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col report-item-modern__title font-weight-500"
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.title) +
                                "\n              "
                            )
                          ]
                        ),
                        _vm.$slots.status && _vm.isCompleted
                          ? _c(
                              "small",
                              { staticClass: "col-12 ml-2 small" },
                              [[_vm._t("status")]],
                              2
                            )
                          : _vm._e()
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "report-item__content" },
                      [
                        _vm.isCompleted
                          ? [_vm._t("default")]
                          : _vm.isNotRequired
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "report-item-modern__subtitle-wrapper"
                              },
                              [
                                _vm.missingRequirements
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "report-item-modern__subtitle"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-modern__missing"
                                          },
                                          [
                                            _vm._v(
                                              "\n                    Нужно добавить\n                    "
                                            ),
                                            _vm._l(
                                              _vm.missingRequirements,
                                              function(item, i) {
                                                return _c(
                                                  "span",
                                                  { key: item },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(item) +
                                                        "\n                      "
                                                    ),
                                                    i !==
                                                      _vm.missingRequirements
                                                        .length -
                                                        1 &&
                                                    _vm.missingRequirements
                                                      .length > 1
                                                      ? [
                                                          _vm._v(
                                                            "\n                        ,\n                      "
                                                          )
                                                        ]
                                                      : _vm._e()
                                                  ],
                                                  2
                                                )
                                              }
                                            )
                                          ],
                                          2
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    staticClass: "report-item-modern__subtitle"
                                  },
                                  [
                                    _vm._t("not-required", [
                                      !_vm.missingRequirements
                                        ? _c(
                                            "span",
                                            { staticClass: "text-warning" },
                                            [_vm._v(" Недостаточно данных ")]
                                          )
                                        : _vm._e()
                                    ])
                                  ],
                                  2
                                )
                              ]
                            )
                          : _vm.isNotPaid
                          ? [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-modern__subtitle report-item-modern__missing"
                                },
                                [
                                  _vm._v(
                                    "\n                  Доступно после заказа\n                "
                                  )
                                ]
                              )
                            ]
                          : [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-modern__subtitle report-item-modern__missing"
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "\n                    Проверка в данный момент находится в работе "
                                    ),
                                    _c("i", {
                                      staticClass:
                                        "fas fa-spinner fa-sm fa-spin text-info ml-2"
                                    })
                                  ])
                                ]
                              ),
                              _vm.isCanceled
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-modern__subtitle report-item-modern__missing"
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Сервис временно недоступен. Проверка будет завершена позднее.\n                "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                      ],
                      2
                    )
                  ]),
                  _vm.source && !_vm.$isServer
                    ? _c(
                        "div",
                        { staticClass: "col-auto report-item-modern__source" },
                        [
                          _c("div", { staticClass: "d-none d-md-block" }, [
                            _vm._v(
                              "\n              Источник\n              " +
                                _vm._s(_vm.source.domain) +
                                "\n            "
                            )
                          ]),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tippy",
                                  rawName: "v-tippy",
                                  value: {
                                    placement: "top-end",
                                    interactive: true
                                  },
                                  expression:
                                    "{ placement: 'top-end', interactive: true }"
                                }
                              ],
                              staticClass: "report-item-modern__source-icon"
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-sm fa-info-circle"
                              }),
                              _c(
                                "div",
                                { attrs: { "data-tippy-content": "hide" } },
                                [
                                  _vm._v("\n                Источник "),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: _vm.source.link,
                                        target: "_blank"
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.source.domain))]
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm.images && _vm.images.length && !_vm.$isServer
                            ? _c(
                                "div",
                                {
                                  staticClass: "report-item-modern__source-icon"
                                },
                                [
                                  _c("i", {
                                    staticClass: "fas fa-camera",
                                    on: { click: _vm.showImage }
                                  })
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    : _vm._e()
                ])
              ]),
              _vm.isCompleted ? [_vm._t("extra-content")] : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }