






















































import Vue from 'vue';
import Component from 'vue-class-component';
import get from 'lodash/get';

@Component({
  components: { },
  props: {
    result: Array,
    user: Object,
  },
})
export default class AppFizcheckExpertSummary extends Vue {
  // props
  result: any[];
  user: any;
  get = get;

  currentFilter: string = '';

  created() {
    this.currentFilter = get(this.result, '0.group_name', '');
  }

  get selectedResult() {
    return this.result.find((elem: any) => {
      return elem.group_name === this.currentFilter;
    });
  }

  filterSelect(value: string) {
    this.currentFilter = value;
  }
}
