





















































import Vue from 'vue';
import Component from 'vue-class-component';
import AppReportItem from 'shared/components/FizCheck/ReportItemModern.vue';
import get from 'lodash/get';
import isString from 'lodash/isString';

@Component({
  components: { AppReportItem },
  props: {
    report: Object,
    status: String,
    images: Array,
    orderIsDone: Boolean,
    missingRequirements: Array,
    openAddFieldDialog: Function,
    updateMap: Function,
    task: Object,
    isPhysical: Boolean,
    onlyOwners: Boolean,
    map: Object,
    extendedReportEnabled: Boolean,
  },
})
export default class AppPhoneReport extends Vue {
  // props
  missingRequirements: any[];
  report: any;
  status: string;
  images: any[];
  orderIsDone: boolean;
  task: any;
  isPhysical: boolean;
  onlyOwners: boolean;
  openAddFieldDialog: (val: string) => any;
  updateMap: (taskName: string, field: string, value: any) => void;
  map: any;
  extendedReportEnabled: boolean;

  // data
  showMessage: boolean = false;
  resultStatusTrace: any = null;

  created() {
    this.resultStatusTrace = this.resultStatus;
  }

  get taskResult() {
    const res = get(this.task, 'task_result.result');
    if (!res || isString(res)) {
      return null;
    }
    return res.find((el: any) => el.name === 'sum');
  }

  get ulixesItems() {
    return get(this.taskResult, 'users.0.items.0.ITEMS.0.ITEMS.0');
  }

  get user() {
    return this.taskResult.users[0];
  }

  get result() {
    return get(this.task, 'task_result.result');
  }

  get haveResult() {
    return !!(this.result && this.result.length);
  }

  get statusDone() {
    return this.status === 'done';
  }

  get resultStatus() {
    if (this.task && this.statusDone) {
      if (this.ulixesItems) {
        this.updateColor('warning');
        return 'found';
      } else {
        this.updateColor('success');
        return 'not found';
      }
    }
    this.updateColor('info');
    return 'no data';
  }

  updateColor(color: string) {
    this.updateMap('phone', 'color', color);
  }

  getTask(val: string) {
    return this.report.tasks.find((elem: any) => elem.task_type === val);
  }
}
