var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-statement-item",
    {
      attrs: {
        "is-disabled": _vm.isDisabled,
        "is-open": _vm.openStatement || !!_vm.ownersCount
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              _c("i", { staticClass: "fas fa-user-friends fa-fw mr-1 icon" }),
              _vm._v(" Текущие собственники\n    ")
            ]
          },
          proxy: true
        },
        {
          key: "status",
          fn: function() {
            return [
              !_vm.status.isPurchased && _vm.showPayInfo
                ? [
                    _c("client-only", [
                      _c("div", { staticClass: "risk-report-item__status" }, [
                        _c("i", {
                          staticClass: "fas fa-lock mr-2 text-danger"
                        }),
                        _c("span", { staticClass: "d-none d-md-inline" }, [
                          _vm._v("Доступно после заказа")
                        ]),
                        _c("img", {
                          staticClass: "ml-md-4",
                          attrs: { src: "/images/eye.svg" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("icon-click")
                            }
                          }
                        })
                      ])
                    ])
                  ]
                : [
                    _vm.status.isPurchased && !_vm.status.isCompleted
                      ? [
                          _c("i", {
                            staticClass: "fas fa-info-circle text-info"
                          }),
                          _vm._v(" В работе\n      ")
                        ]
                      : !_vm.ownersCount
                      ? [
                          _c("i", {
                            staticClass:
                              "fas fa-exclamation-circle text-warning"
                          }),
                          _vm._v(" Отсутствуют\n      ")
                        ]
                      : [
                          _c("i", {
                            staticClass: "fas fa-check-circle text-success"
                          }),
                          _vm._v(
                            " Собственников: " +
                              _vm._s(_vm.ownersCount) +
                              "\n      "
                          )
                        ]
                  ]
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.ownerlessData && _vm.ownerlessData.authority_name
        ? [
            _c("div", { staticClass: "owners" }, [
              _c("div", { staticClass: "owners__register-date mb-2" }, [
                _c("i", { staticClass: "fas fa-check mr-2 text-success" }),
                _c(
                  "span",
                  { staticClass: "font-weight-500" },
                  [
                    _vm.ownerlessInfo && _vm.ownerlessInfo.registration_date
                      ? [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.ownerlessInfo.registration_date) +
                              "\n          "
                          )
                        ]
                      : [_vm._v("\n            Дата отсутствует\n          ")]
                  ],
                  2
                )
              ]),
              _c("div", { staticClass: "owners__item pl-4" }, [
                _c("div", { staticClass: "owners__item" }, [
                  _c("div", { staticClass: "font-weight-500" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.ownerlessData.authority_name) +
                        "\n          "
                    )
                  ]),
                  _c("small", [
                    _c("div", { staticClass: "text-muted" }, [
                      _vm._v(
                        "\n              Вид, номер, дата и время государственной регистрации права:\n              "
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "encumbrance-item__row-content font-weight-500"
                        },
                        [
                          _vm._v(
                            "\n                Принят на учет как бесхозяйный объект недвижимости,\n                "
                          ),
                          _vm.ownerlessInfo.registration_date
                            ? [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.ownerlessInfo.registration_date
                                    ) +
                                    ",\n                "
                                )
                              ]
                            : _vm._e(),
                          _vm.ownerlessData.ownerless_right_number
                            ? [
                                _vm._v(
                                  "\n                  № " +
                                    _vm._s(
                                      _vm.ownerlessData.ownerless_right_number
                                    ) +
                                    "\n                "
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ])
                  ])
                ])
              ])
            ])
          ]
        : _vm._e(),
      _vm.currentOwners
        ? _vm._l(_vm.currentOwners.rights, function(right, i) {
            return _c(
              "div",
              { key: right.registration.reg_date + i, staticClass: "owners" },
              [
                _c("div", { staticClass: "owners__register-date mb-2" }, [
                  _c("i", { staticClass: "fas fa-check mr-2 text-success" }),
                  _c("span", { staticClass: "font-weight-500" }, [
                    _vm._v(_vm._s(right.registration.reg_date))
                  ])
                ]),
                _vm._l(right.entities, function(owner, ownerIndex) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isOwnership(right) ? ownerIndex < 1 : true,
                          expression:
                            "isOwnership(right) ? ownerIndex < 1 : true"
                        }
                      ],
                      key: ownerIndex,
                      staticClass: "owners__item pl-4"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "owners__item" },
                        [
                          _vm._l(right.entities, function(entity, entityIndex) {
                            return _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isOwnership(right)
                                      ? true
                                      : ownerIndex < 1,
                                    expression:
                                      "isOwnership(right) ? true : ownerIndex < 1"
                                  }
                                ],
                                key: entityIndex + "i",
                                staticClass: "font-weight-500"
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(entity.content) +
                                    " "
                                ),
                                _vm.isOwnership(right) &&
                                right.entities.length > 1
                                  ? [
                                      _vm._v(
                                        "\n              №" +
                                          _vm._s(entityIndex + 1) +
                                          "\n            "
                                      )
                                    ]
                                  : _vm._e(),
                                entity.birth_date
                                  ? _c("span", [
                                      _vm._v(
                                        ", дата рождения: " +
                                          _vm._s(
                                            _vm._f("date")(
                                              entity.birth_date,
                                              "DD.MM.YYYY"
                                            )
                                          )
                                      )
                                    ])
                                  : _vm._e(),
                                /Физическое лицо/.test(entity.content) &&
                                !_vm.$isServer
                                  ? _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "tippy",
                                            rawName: "v-tippy",
                                            value: {
                                              placement: "top-end",
                                              content: _vm.fizTip
                                            },
                                            expression:
                                              "{\n                placement: 'top-end',\n                content: fizTip,\n              }"
                                          }
                                        ],
                                        staticClass: "d-inline-block"
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fas fa-info-circle text-muted"
                                        })
                                      ]
                                    )
                                  : _c("div", { staticClass: "small" }, [
                                      entity.inn
                                        ? _c("div", [
                                            _vm._v(
                                              "\n                ИНН: " +
                                                _vm._s(entity.inn) +
                                                "\n              "
                                            )
                                          ])
                                        : _vm._e(),
                                      entity.ogrn
                                        ? _c("div", [
                                            _vm._v(
                                              "\n                ОГРН: " +
                                                _vm._s(entity.ogrn) +
                                                "\n              "
                                            )
                                          ])
                                        : _vm._e()
                                    ])
                              ],
                              2
                            )
                          }),
                          _vm.regName(owner)
                            ? _c(
                                "small",
                                { staticClass: "d-block text-muted" },
                                [
                                  _c("b", { staticClass: "font-weight-500" }, [
                                    _vm._v(_vm._s(_vm.regName(owner)))
                                  ])
                                ]
                              )
                            : _vm._e(),
                          owner.rightList &&
                          owner.rightList.docs &&
                          owner.rightList.docs.length
                            ? _c("small", [
                                _c(
                                  "div",
                                  { staticClass: "text-muted" },
                                  [
                                    _vm._v(
                                      "\n              Основание права собственности:\n              "
                                    ),
                                    _vm._l(owner.rightList.docs, function(
                                      d,
                                      docIndex
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: docIndex,
                                          staticClass:
                                            "encumbrance-item__row-content font-weight-500"
                                        },
                                        [
                                          owner.rightList.docs.length > 1
                                            ? _c("span", [
                                                _vm._v(
                                                  "\n                  -\n                "
                                                )
                                              ])
                                            : _vm._e(),
                                          d.Content
                                            ? [_vm._v(_vm._s(d.Content))]
                                            : _vm._e(),
                                          d.Name &&
                                          d.Name.toLowerCase() !==
                                            d.Content.toLowerCase()
                                            ? [_vm._v(": " + _vm._s(d.Name))]
                                            : _vm._e(),
                                          d.Number
                                            ? [
                                                _vm._v(
                                                  "\n                  № " +
                                                    _vm._s(d.Number) +
                                                    ",\n                "
                                                )
                                              ]
                                            : _vm._e(),
                                          d.DocumentIssuer
                                            ? [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(d.DocumentIssuer) +
                                                    ",\n                "
                                                )
                                              ]
                                            : _vm._e(),
                                          d.IssueOrgan
                                            ? [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(d.IssueOrgan) +
                                                    ",\n                "
                                                )
                                              ]
                                            : _vm._e(),
                                          d.Date
                                            ? [
                                                _vm._v(
                                                  "\n                  дата документа: " +
                                                    _vm._s(
                                                      _vm._f("date")(
                                                        d.Date,
                                                        "DD.MM.YYYY"
                                                      )
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            : _vm._e()
                                        ],
                                        2
                                      )
                                    })
                                  ],
                                  2
                                )
                              ])
                            : _vm._e()
                        ],
                        2
                      )
                    ]
                  )
                }),
                _vm.showCheckPersonLink && i === 0 && !_vm.$isServer
                  ? [
                      _c(
                        "app-button",
                        {
                          staticClass: "mt-3",
                          attrs: {
                            variant: "primary-light",
                            size: "lg",
                            block: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.goCheckPerson(_vm.owners[0])
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          Проверить собственников\n        "
                          )
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticClass:
                            "mt-3 text-muted text-center owners__info"
                        },
                        [
                          _vm._v(
                            "\n          Узнайте о долгах у судебных приставов и налоговой, о наличии судебных дел, банкротство и розыск МВД из официальных источников\n        "
                          )
                        ]
                      )
                    ]
                  : _vm._e()
              ],
              2
            )
          })
        : _vm._e(),
      !_vm.status.isCompleted && _vm.rights && _vm.rights.length
        ? [
            _c("small", { staticClass: "text-muted" }, [
              _vm._v(
                "Информация может быть устаревшей. Актуальные данные будут доступны после выполнения заказа."
              )
            ]),
            _vm._l(_vm.rights, function(right, index) {
              return _c("div", { key: index, staticClass: "owners__item" }, [
                _c("div", { staticClass: "owners__item" }, [
                  _c("b", { staticClass: "font-weight-500" }, [
                    _vm._v("Собственник №" + _vm._s(index + 1))
                  ]),
                  _c("small", { staticClass: "d-block text-muted" }, [
                    _c(
                      "b",
                      { staticClass: "font-weight-500" },
                      [
                        _vm.numberPropNotContainsType(right.number, right.type)
                          ? [_vm._v(_vm._s(right.type))]
                          : _vm._e(),
                        _vm._v(" " + _vm._s(right.number) + " "),
                        right.date &&
                        _vm.numberPropNotContiansDate(right.number, right.date)
                          ? [_vm._v("от " + _vm._s(right.date))]
                          : _vm._e()
                      ],
                      2
                    )
                  ])
                ])
              ])
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }