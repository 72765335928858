var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "person-info-modern" }, [
    _c(
      "div",
      { staticClass: "row person-info-modern__section" },
      [
        _vm.isPhysical
          ? [
              !_vm.ownerBirthdayIsEmpty
                ? _c(
                    "div",
                    {
                      staticClass:
                        "person-info-modern__item col-12 col-md-4 col-lg-3"
                    },
                    [
                      _c("div", { staticClass: "person-info-modern__title" }, [
                        _vm._v("\n          Дата рождения\n        ")
                      ]),
                      _c(
                        "div",
                        { staticClass: "person-info-modern__data" },
                        [
                          _vm.owner.birthday
                            ? [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm._f("date")(
                                        _vm.owner.birthday,
                                        "DD.MM.YYYY"
                                      )
                                    ) +
                                    "\n          "
                                )
                              ]
                            : _vm.$isServer
                            ? [_vm._v("\n            -\n          ")]
                            : [
                                _c(
                                  "span",
                                  {
                                    staticClass: "link d-print-none",
                                    on: {
                                      click: function($event) {
                                        return _vm.addField("birthday")
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              Добавить\n            "
                                    )
                                  ]
                                )
                              ]
                        ],
                        2
                      )
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass:
                    "person-info-modern__item col-12 col-md-4 col-lg-3"
                },
                [
                  _c("div", { staticClass: "person-info-modern__title" }, [
                    _vm._v("\n          Паспорт\n        ")
                  ]),
                  _c(
                    "div",
                    { staticClass: "person-info-modern__data" },
                    [
                      _vm.owner.passport
                        ? [
                            _c("span", { staticClass: "d-print-none" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatPassport")(_vm.owner.passport)
                                )
                              )
                            ]),
                            _c(
                              "span",
                              { staticClass: "d-print-block d-none" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatPassport")(
                                      _vm.owner.passport,
                                      true
                                    )
                                  ) + " ******"
                                )
                              ]
                            )
                          ]
                        : _vm.$isServer
                        ? [_vm._v("\n            -\n          ")]
                        : [
                            _c(
                              "span",
                              {
                                staticClass: "link d-print-none",
                                on: {
                                  click: function($event) {
                                    return _vm.addField("passport")
                                  }
                                }
                              },
                              [_vm._v("\n              Добавить\n            ")]
                            )
                          ]
                    ],
                    2
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "person-info-modern__item col-12 col-md-4 col-lg-3"
                },
                [
                  _c("div", { staticClass: "person-info-modern__title" }, [
                    _vm._v("\n          Водительское удостоверение\n        ")
                  ]),
                  _c(
                    "div",
                    { staticClass: "person-info-modern__data" },
                    [
                      _vm.owner.license
                        ? [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.owner.license) +
                                "\n          "
                            )
                          ]
                        : _vm.$isServer
                        ? [_vm._v("\n            -\n          ")]
                        : [
                            _c(
                              "span",
                              {
                                staticClass: "link d-print-none",
                                on: {
                                  click: function($event) {
                                    return _vm.addField("license")
                                  }
                                }
                              },
                              [_vm._v("\n              Добавить\n            ")]
                            )
                          ]
                    ],
                    2
                  )
                ]
              )
            ]
          : _vm._e(),
        _c(
          "div",
          { staticClass: "person-info-modern__item col-12 col-md-4 col-lg-3" },
          [
            _c("div", { staticClass: "person-info-modern__title" }, [
              _vm._v("\n        Номер телефона\n      ")
            ]),
            _c(
              "div",
              { staticClass: "person-info-modern__data" },
              [
                _vm.owner.phone
                  ? [
                      _vm._v(
                        "\n          " + _vm._s(_vm.owner.phone) + "\n        "
                      )
                    ]
                  : _vm.$isServer
                  ? [_vm._v("\n          -\n        ")]
                  : [
                      _c(
                        "span",
                        {
                          staticClass: "link d-print-none",
                          on: {
                            click: function($event) {
                              return _vm.addField("phone")
                            }
                          }
                        },
                        [_vm._v("\n            Добавить\n          ")]
                      )
                    ]
              ],
              2
            )
          ]
        )
      ],
      2
    ),
    _c("div", { staticClass: "row person-info-modern__section" }, [
      _c(
        "div",
        { staticClass: "person-info-modern__item col-12 col-md-4 col-lg-3" },
        [
          _c("div", { staticClass: "person-info-modern__title" }, [
            _vm._v("\n        Регион\n      ")
          ]),
          _c(
            "div",
            { staticClass: "person-info-modern__data" },
            [
              _vm.region
                ? [_vm._v("\n          " + _vm._s(_vm.region) + "\n        ")]
                : _vm.$isServer
                ? [_vm._v("\n          -\n        ")]
                : [
                    _c(
                      "span",
                      {
                        staticClass: "link d-print-none",
                        on: {
                          click: function($event) {
                            return _vm.addField("owner")
                          }
                        }
                      },
                      [_vm._v("\n            Добавить\n          ")]
                    )
                  ]
            ],
            2
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "person-info-modern__item col-12 col-md-4 col-lg-3" },
        [
          _c("div", { staticClass: "person-info-modern__title" }, [
            _vm._v("\n        ИНН\n      ")
          ]),
          _c(
            "div",
            { staticClass: "person-info-modern__data" },
            [
              _vm.inn.task_result.inn
                ? [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.inn.task_result.inn) +
                        "\n        "
                    )
                  ]
                : _vm.owner.inn
                ? [
                    _vm._v(
                      "\n          " + _vm._s(_vm.owner.inn) + "\n          "
                    )
                  ]
                : !_vm.inn
                ? [_vm._m(0)]
                : _vm.$isServer
                ? [_vm._v("\n          -\n        ")]
                : _vm._e(),
              _vm.inn
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "person-info-modern__data-status" },
                      [
                        _vm.innManualAdded
                          ? _c("span", [
                              _vm._v(
                                "\n              Добавлен пользователем\n            "
                              )
                            ])
                          : !_vm.innManualAdded && _vm.inn.task_result.inn
                          ? _c("span", [
                              _vm._v("\n              В базе ФНС\n            ")
                            ])
                          : _vm.inn.status === "in progress"
                          ? _c("span", [
                              _vm._v("\n              В работе "),
                              _c("i", {
                                staticClass:
                                  "fas fa-spinner fa-sm fa-spin text-brand ml-2"
                              })
                            ])
                          : _vm.owner.inn
                          ? _c("span", [
                              _vm._v(
                                "\n              Добавлен пользователем\n            "
                              )
                            ])
                          : ["failed", "soft_failed"].includes(_vm.inn.status)
                          ? _c("span", [
                              _vm._v(
                                "\n              Сервис временно недоступен\n            "
                              )
                            ])
                          : [
                              _c("div", {}, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "link d-print-none",
                                    on: {
                                      click: function($event) {
                                        return _vm.addField("inn")
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Добавить\n                "
                                    )
                                  ]
                                )
                              ])
                            ]
                      ],
                      2
                    )
                  ])
                : _vm._e()
            ],
            2
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "person-info-modern__item col-12 col-md-4 col-lg-3" },
        [
          _c("div", { staticClass: "person-info-modern__title" }, [
            _vm._v("\n        СНИЛС\n      ")
          ]),
          _c(
            "div",
            { staticClass: "person-info-modern__data" },
            [
              _vm.owner.snils
                ? [
                    _vm._v(
                      "\n          " + _vm._s(_vm.owner.snils) + "\n        "
                    )
                  ]
                : _vm.$isServer
                ? [_vm._v("\n          -\n        ")]
                : [
                    _c(
                      "span",
                      {
                        staticClass: "link d-print-none",
                        on: {
                          click: function($event) {
                            return _vm.addField("snils")
                          }
                        }
                      },
                      [_vm._v("\n            Добавить\n          ")]
                    )
                  ]
            ],
            2
          )
        ]
      ),
      _vm.getSurvey.result && _vm.getSurvey.result.length
        ? _c(
            "div",
            { staticClass: "person-info-modern__item col-12 pt-3" },
            [
              _c("h6", [_vm._v("\n        Дополнительные сведения\n      ")]),
              _vm._l(_vm.getSurvey.result, function(elem) {
                return _c(
                  "div",
                  { key: elem.number, staticClass: "mb-2" },
                  [
                    _vm.itsArray(elem.answer)
                      ? [
                          elem.answer.length
                            ? _c("span", { staticClass: "small text-muted" }, [
                                _vm._v(
                                  _vm._s(_vm.getQuestionText(elem.number)) +
                                    ": "
                                )
                              ])
                            : _vm._e(),
                          _c(
                            "ul",
                            { staticClass: "list-unstyled mt-1 mb-0" },
                            _vm._l(elem.answer, function(answer, i) {
                              return _c(
                                "li",
                                { key: i, staticClass: "mb-2" },
                                _vm._l(answer, function(field, name) {
                                  return _c(
                                    "div",
                                    { key: name + i, staticClass: "pl-3" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "small text-muted" },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.translate(name)) +
                                              ":\n                "
                                          )
                                        ]
                                      ),
                                      _vm.isDateField(name)
                                        ? _c("span", [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm._f("date")(
                                                    field,
                                                    "DD.MM.YYYY"
                                                  )
                                                ) +
                                                "\n                "
                                            )
                                          ])
                                        : _c("span", [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(field) +
                                                "\n                "
                                            )
                                          ])
                                    ]
                                  )
                                }),
                                0
                              )
                            }),
                            0
                          )
                        ]
                      : [
                          _c("span", { staticClass: "small text-muted" }, [
                            _vm._v(
                              _vm._s(_vm.getQuestionText(elem.number)) + ": "
                            )
                          ]),
                          _c("span", [_vm._v(_vm._s(elem.answer))])
                        ]
                  ],
                  2
                )
              })
            ],
            2
          )
        : _vm._e()
    ]),
    _c("div", { staticClass: "person-info-modern__info-panels" }, [
      _vm.resultTextIsRu
        ? _c("div", { staticClass: "rc-modern-info-panel" }, [
            _c(
              "div",
              { staticClass: "rc-modern-info-panel__wrapper" },
              [
                _c(
                  "div",
                  [_c("app-warning-img", { staticClass: "danger-modern-svg" })],
                  1
                ),
                _c("div", [
                  _vm._v("\n          " + _vm._s(_vm.resultText) + "\n        ")
                ]),
                [
                  _c(
                    "span",
                    {
                      staticClass: "font-weight-500 ml-auto cursor-pointer",
                      on: {
                        click: function($event) {
                          return _vm.addField("owner")
                        }
                      }
                    },
                    [_vm._v("\n            Редактировать\n          ")]
                  )
                ]
              ],
              2
            )
          ])
        : _vm._e(),
      !_vm.owner.passport || !_vm.owner.birthday || !_vm.owner.snils
        ? _c("div", { staticClass: "rc-modern-info-panel" }, [
            _c("div", { staticClass: "rc-modern-info-panel__wrapper" }, [
              _c("div", [_c("app-warning-img")], 1),
              _c("div", [
                _vm._v(
                  "\n          Добавьте больше персональных данных для получения полного отчета.\n        "
                )
              ])
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "person-info-modern__data-status" }, [
      _c("i", { staticClass: "far fa-stop-circle" }),
      _vm._v(" Недостаточно данных\n          ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }