













































import Vue from 'vue';

import AppFounderDetailsDialog from 'shared/components/RiskNew/FounderDetails.vue';
import AppFounderElem from 'shared/components/RiskNew/FounderElem.vue';

export default Vue.extend({
  // eslint-disable-next-line
  components: { AppFounderDetailsDialog, AppFounderElem },

  props: {
    result: Object,
    date: String,
  },

  computed: {

    sorted() {
      const active: any = [];
      const disabled: any = [];
      if (this.result.result && this.result.result.length) {
        this.result.result.forEach((elem: any) => {
          if (elem.individual_data && elem.individual_data.roles) {
            if (elem.org_data.death_date) {
              disabled.push(elem);
              return;
            }
            const res = elem.individual_data.roles.filter((role: any) => { return role.active === true; });
            if (res.length) {
              active.push(elem);
            } else {
              disabled.push(elem);
            }
          }
        });
      }
      return { active, disabled };
    },
  },
  methods: {
    showDetails() {
      this.$dialog.open({
        component: AppFounderDetailsDialog,

        props: {
          result: this.sorted,
          date: this.date,
        },
      });
    },
  },
});
