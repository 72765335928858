var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isPhysical && _vm.onlyOwners
    ? _c(
        "app-report-item",
        {
          attrs: {
            title: "Реестр залогов",
            icon: "hand-holding-usd",
            status: _vm.status,
            images: _vm.images,
            color: _vm.map.color,
            opened: _vm.resultStatus === "found",
            "missing-requirements": _vm.missingRequirements,
            source: {
              domain: "reestr-zalogov.ru",
              link: "https://www.reestr-zalogov.ru/search/index"
            }
          },
          scopedSlots: _vm._u(
            [
              _vm.resultStatus === "found"
                ? {
                    key: "extra-content",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          { staticClass: "report-item-result__graph" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "report-item-result__graph-counters"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "report-item-result__graph-counters-elem"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "report-item-result__graph-counters-elem-key"
                                      },
                                      [
                                        _vm._v(
                                          "\n            Количество записей\n          "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "report-item-result__graph-counters-elem-result"
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(_vm.cases.length) +
                                            "\n          "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "report-item-result__graph-counters-elem"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "report-item-result__graph-counters-elem-key"
                                      },
                                      [
                                        _vm._v(
                                          "\n            Дата проверки\n          "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "report-item-result__graph-counters-elem-result"
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              _vm._f("date")(
                                                _vm.get(
                                                  _vm.task,
                                                  "task_result.result.datetime"
                                                ),
                                                "DD.MM.YYYY"
                                              )
                                            ) +
                                            "\n          "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        ),
                        _vm.cases.length > 1
                          ? _c(
                              "div",
                              {
                                staticClass: "text-right report-open-all mt-3"
                              },
                              [
                                !_vm.$isServer
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "link",
                                        on: {
                                          click: function($event) {
                                            _vm.opened = !_vm.opened
                                          }
                                        }
                                      },
                                      [
                                        !_vm.opened
                                          ? [
                                              _vm._v(
                                                "\n          Раскрыть все\n        "
                                              )
                                            ]
                                          : [
                                              _vm._v(
                                                "\n          Скрыть все\n        "
                                              )
                                            ]
                                      ],
                                      2
                                    )
                                  : _vm._e()
                              ]
                            )
                          : _vm._e(),
                        _c("app-pledge-report-details", {
                          attrs: {
                            opened: _vm.opened || _vm.isPdf,
                            result: _vm.$isServer
                              ? _vm.slicedCases
                              : _vm.task.task_result
                          }
                        }),
                        _vm.slicedCases.length < _vm.cases.length &&
                        _vm.$isServer
                          ? _c(
                              "div",
                              { staticClass: "rc-modern-info-panel mt-3" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "rc-modern-info-panel__wrapper"
                                  },
                                  [
                                    _c("div", [_c("app-warning-img")], 1),
                                    _c("div", [
                                      _vm._v(
                                        "\n          Отображено " +
                                          _vm._s(_vm.slicedCases.length) +
                                          " дел из " +
                                          _vm._s(_vm.cases.length) +
                                          ", полная информация доступна в веб версии отчета.\n        "
                                      )
                                    ])
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  }
                : null
            ],
            null,
            true
          )
        },
        [
          _vm.task
            ? _c(
                "div",
                { staticClass: "report-item-modern__subtitle-wrapper" },
                [
                  _vm.resultStatus === "found"
                    ? [
                        _c(
                          "div",
                          { staticClass: "report-item-modern__subtitle" },
                          [
                            _vm._v(
                              "\n        В базе федеральной нотариальной палаты числится залогодателем\n      "
                            )
                          ]
                        )
                      ]
                    : [
                        _c(
                          "div",
                          { staticClass: "report-item-modern__subtitle" },
                          [
                            _vm._v(
                              "\n        В реестре залогов записи не найдены\n      "
                            )
                          ]
                        )
                      ]
                ],
                2
              )
            : _vm.orderIsDone
            ? [
                _c("span", { staticClass: "text-warning" }, [
                  _vm._v(" Недостаточно данных ")
                ])
              ]
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }