





























































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import AppReportItem from 'shared/components/FizCheck/ReportItemModern.vue';
import AppFsspDetails from 'shared/components/FizCheck/Details/FsspDetails/FsspDetails.vue';
import AppFsspGosuslugiDetails from 'shared/components/FizCheck/Details/FsspDetails/FsspGosuslugiDetails.vue';
import AppWarningImg from 'shared/components/Static/WarningImg.vue';
import get from 'lodash/get';
import concat from 'lodash/concat';
import filter from 'lodash/filter';
import sumBy from 'lodash/sumBy';

@Component({
  components: { AppReportItem, AppFsspDetails, AppFsspGosuslugiDetails, AppWarningImg },
  props: {
    report: Object,
    status: String,
    images: Array,
    orderIsDone: Boolean,
    missingRequirements: Array,
    updateMap: Function,
    openAddFieldDialog: Function,
    task: Object,
    isPhysical: Boolean,
    onlyOwners: Boolean,
    map: Object,
    isPdf: Boolean,
  },
})
export default class AppDebtReportModern extends Vue {
  // props
  missingRequirements: any[];
  report: any;
  status: string;
  images: any[];
  orderIsDone: boolean;
  task: any;
  isPhysical: boolean;
  onlyOwners: boolean;
  openAddFieldDialog: (val: string) => any;
  updateMap: (taskName: string, field: string, value: any) => void;
  map: any;
  currentFilter: string = 'main';
  opened: boolean = false;
  isPdf: boolean;

  @Watch('fsspTaskFailed')
  fsspStatusChanged(): void {
    if (this.fsspTaskFailed || !this.counters.cases) {
      this.currentFilter = 'gosuslugi';
    } else {
      this.currentFilter = 'main';
    }
  }

  created() {
    if (this.fsspTaskFailed || !this.counters.cases) {
      this.currentFilter = 'gosuslugi';
    }
  }

  get result() {
    const res = get(this.task, 'task_result.debts');
    if (res) {
      return res;
    }
    return [];
  }

  get statusDone() {
    return this.status === 'done';
  }

  get resultStatus() {
    if (this.task && this.statusDone) {
      if ((this.result && this.result.length) ||
        (this.gosuslugiBillsResult && this.gosuslugiBillsResult.length)) {
        this.updateColor('danger');
        return 'found';
      } else {
        this.updateColor('success');
        return 'not found';
      }
    }
    this.updateColor('info');
    return 'no data';
  }

  get gosuslugiBills() {
    return this.getTask('gosuslugi_bills');
  }

  get fsspTask() {
    return this.getTask('owner_check_individual_bailiff_debt');
  }

  get fsspTaskFailed() {
    const status = get(this.fsspTask, 'status', '');
    return [ 'failed', 'soft_failed' ].includes(status);
  }

  get gosuslugiBillsResult() {
    const result = get(this.gosuslugiBills, 'task_result.result');
    if (!result) {
      return [];
    }
    return concat(
      filter(get(result, 'inn.bills'), this.mapBills),
      filter(get(result, 'passport.bills'), this.mapBills),
      filter(get(result, 'snils.bills'), this.mapBills),
    );
  }

  get counters() {
    let cases = 0;
    let sum = 0;
    let casesGos = 0;
    let sumGos = 0;
    if (this.result && this.result.length) {
      cases += this.result.length;
      if (this.task.task_result.money) {
        sum = this.task.task_result.money;
      }
    } if (this.gosuslugiBillsResult.length) {
      casesGos += this.gosuslugiBillsResult.length;
      sumGos += sumBy(this.gosuslugiBillsResult, 'amount');
    }
    return { cases, casesGos, sum, sumGos };
  }

  get slicedCases() {
    const cases: any = {
      main: this.result.slice(0, 20),
      gosuslugi: this.gosuslugiBillsResult.slice(0, 20),
    };
    cases.count = cases.main.length + cases.gosuslugi.length;
    return cases;
  }

  mapBills(elem: any) {
    return elem.serviceCategory.code === 'FSSP';
  }

  getTask(val: string) {
    return this.report.tasks.find((elem: any) => elem.task_type === val);
  }

  updateColor(color: string) {
    this.updateMap('bailiffDebt', 'color', color);
  }
}
