var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "risk-pdf" }, [
    !_vm.onlyOwners
      ? _c("section", { staticClass: "pdf-item" }, [
          _vm.riskReport
            ? _c("div", { staticClass: "title-page" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "row align-items-center justify-content-between"
                  },
                  [
                    _c("div", { staticClass: "col-auto" }, [
                      _c(
                        "div",
                        { staticClass: "title-page__logo" },
                        [
                          _vm.riskReport.order.input_data.logo_info &&
                          _vm.riskReport.order.input_data.logo_info.logo_url
                            ? _c("img", {
                                attrs: {
                                  src:
                                    _vm.riskReport.order.input_data.logo_info
                                      .logo_url
                                }
                              })
                            : _vm.haveLogo
                            ? _c("img", { attrs: { src: _vm.haveLogo } })
                            : _c("app-logo-svg")
                        ],
                        1
                      )
                    ]),
                    !_vm.hideEcp
                      ? _c(
                          "div",
                          { staticClass: "col-auto" },
                          [_c("app-ecp-svg", { attrs: { width: "260px" } })],
                          1
                        )
                      : _vm._e()
                  ]
                ),
                _c("div", { staticClass: "title-page__created" }, [
                  _vm._v(
                    "\n        Выполнен: " +
                      _vm._s(
                        _vm._f("date")(
                          _vm.riskReport.order.created_at,
                          "DD.MM.YYYY"
                        )
                      ) +
                      " "
                  ),
                  _c("br"),
                  _vm.riskReport.order.input_data.logo_info &&
                  _vm.riskReport.order.input_data.logo_info.name
                    ? _c("span", [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.riskReport.order.input_data.logo_info.name
                            ) +
                            "\n        "
                        )
                      ])
                    : _vm.companyName
                    ? _c("span", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.companyName) +
                            "\n        "
                        )
                      ])
                    : _c("span", [
                        _vm._v("\n          RealtyCloud.ru\n        ")
                      ])
                ]),
                _c("div", { staticClass: "title-page__head" }, [
                  _c("h1", [_vm._v("Оценка рисков по объекту недвижимости")]),
                  _c("div", { staticClass: "title-page__object" }, [
                    _c("h2", { staticClass: "title-page__object-number" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.info.Number) +
                          "\n          "
                      )
                    ]),
                    _c("div", { staticClass: "title-page__object-address" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.info.Address) +
                          "\n          "
                      )
                    ])
                  ])
                ]),
                _vm._m(0),
                _c("div", { staticClass: "title-page__year" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm._f("date")(_vm.riskReport.order.created_at, "YYYY")
                      ) +
                      " г.\n      "
                  )
                ])
              ])
            : _vm._e()
        ])
      : _vm._e(),
    !_vm.onlyOwners
      ? _c(
          "section",
          { staticClass: "pdf-item mb-3" },
          [
            _vm.info
              ? _c(
                  "app-collapse",
                  [
                    _c(
                      "app-collapse-item",
                      {
                        staticClass: "risk-report-item object-page-collapse",
                        attrs: { opened: true },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "risk-report-item__title-inner"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "risk-report-item__name"
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "far fa-building text-primary fa-sm mr-2"
                                          }),
                                          _vm._v(
                                            "\n              Общая информация об объекте\n            "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          2593061343
                        )
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "pt-3" },
                          [
                            _c("app-rosreestr-info", {
                              attrs: {
                                info: _vm.info,
                                generic: _vm.objectGeneric
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      : _vm._e(),
    !_vm.onlyOwners
      ? _c(
          "section",
          { staticClass: "pdf-item" },
          [
            _c(
              "app-collapse",
              [
                _c("app-statement-encumbrance", {
                  attrs: { statements: _vm.statements, opened: "" }
                }),
                _c("app-statement-restrictions", {
                  attrs: { statements: _vm.statements, opened: "" }
                }),
                _c("app-statement-owners", {
                  attrs: {
                    statements: _vm.statements,
                    opened: "",
                    rights: _vm.info.Rights
                  }
                }),
                _c("app-statement-owners-history", {
                  attrs: { statements: _vm.statements, opened: "" }
                }),
                _c("app-statement-object-plan", {
                  attrs: { images: _vm.objectPlan, opened: "" }
                }),
                _vm.landCadInfo
                  ? _c("app-statement-land-info", {
                      attrs: { info: _vm.landCadInfo, opened: "" }
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      : _vm._e(),
    _c(
      "section",
      { staticClass: "pdf-item" },
      [
        _c("app-risk", {
          attrs: {
            opened: "",
            report: _vm.riskReport,
            "object-key": _vm.objectKey,
            "object-info": _vm.info,
            "fetch-risk": _vm.fetchRisk,
            "only-owners": _vm.onlyOwners,
            "render-type": _vm.renderType
          }
        })
      ],
      1
    ),
    _vm.riskInsurance
      ? _c(
          "section",
          {
            staticClass: "pdf-item insurance",
            class: _vm.$isServer ? "full-height" : ""
          },
          [
            _c("div", { staticClass: "row justify-content-between" }, [
              _c(
                "div",
                { staticClass: "col-auto" },
                [_c("app-logo-new-alt", { staticClass: "insurance__logo" })],
                1
              ),
              _vm._m(1)
            ]),
            _c("div", { staticClass: "text-center" }, [
              _c("h4", { staticClass: "insurance__title-center" }, [
                _vm._v(
                  "\n        Сертификат от " +
                    _vm._s(
                      _vm._f("date")(
                        _vm.riskReport.order.created_at,
                        "DD.MM.YYYY"
                      )
                    ) +
                    "\n      "
                )
              ])
            ]),
            _c("div", { staticClass: "insurance__panel" }, [
              _vm._m(2),
              _c("div", { staticClass: "row mb-5" }, [
                _vm._m(3),
                _c("div", { staticClass: "col" }, [
                  _c(
                    "span",
                    { staticClass: "insurance__text-right font-weight-500" },
                    [_vm._v("\n            Адрес\n          ")]
                  ),
                  _c("br"),
                  _c("span", { staticClass: "insurance__text-right" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.info.Address) +
                        "\n          "
                    )
                  ]),
                  _c("br"),
                  _c(
                    "span",
                    { staticClass: "insurance__text-right font-weight-500" },
                    [_vm._v("\n            Кадастровый номер\n          ")]
                  ),
                  _c("br"),
                  _c("span", { staticClass: "insurance__text-right" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.info.Number) +
                        "\n          "
                    )
                  ])
                ])
              ]),
              _vm._m(4),
              _vm._m(5),
              _vm._m(6),
              _vm._m(7),
              _c("div", { staticClass: "row mb-5" }, [
                _c("div", { staticClass: "col-5" }),
                _c(
                  "div",
                  { staticClass: "col" },
                  [_c("app-ecp-svg", { attrs: { width: "376px" } })],
                  1
                )
              ])
            ]),
            _vm._m(8)
          ]
        )
      : _vm._e(),
    !_vm.onlyOwners
      ? _c("section", { staticClass: "pdf-item" }, [_vm._m(9)])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-page__info" }, [
      _c("div", { staticClass: "title-page__info-row" }, [
        _vm._v(
          "\n          Данный отчет о рисках служит для наглядного представления заказчику\n          информации об объекте недвижимости, его владельцах, а\n          также потенциальных рисках и угрозах (или их отсутствии) при совершении\n          сделок с недвижимостью.\n        "
        )
      ]),
      _c("div", { staticClass: "title-page__info-row" }, [
        _vm._v(
          "\n          Информация представленная в отчете актуальна на момент предоставления\n          отчета. Заказчик самостоятельно принимает решение о покупке и о том\n          следовать ли рекомендациям данного отчета.\n        "
        )
      ]),
      _c("div", { staticClass: "title-page__info-row" }, [
        _vm._v(
          "\n          Вся информация получена легальным способом, путем совершения официальных\n          запросов в соответствующие органы.\n        "
        )
      ]),
      _c("div", { staticClass: "title-page__info-row" }, [
        _vm._v(
          "\n          Вся информация в отчете строго конфиденциальна и не может\n          распространяться и публиковаться.\n        "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-auto" }, [
      _c("span", { staticClass: "insurance__title-right" }, [
        _vm._v("\n          realtycloud.ru\n        ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mb-5" }, [
      _c("div", { staticClass: "col-5" }, [
        _c("span", { staticClass: "insurance__text-left" }, [
          _vm._v("\n            Выдал:\n          ")
        ])
      ]),
      _c("div", { staticClass: "col" }, [
        _c("span", { staticClass: "insurance__text-right" }, [
          _vm._v("\n            ООО «Реалти Клауд»\n          ")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-5" }, [
      _c("span", { staticClass: "insurance__text-left" }, [
        _vm._v("\n            На объект:\n          ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mb-5" }, [
      _c("div", { staticClass: "col-5" }, [
        _c("span", { staticClass: "insurance__text-left" }, [
          _vm._v("\n            Сумма возмещения:\n          ")
        ])
      ]),
      _c("div", { staticClass: "col" }, [
        _c("span", { staticClass: "insurance__text-right" }, [
          _vm._v("\n            3 000 000 рублей\n          ")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mb-5" }, [
      _c("div", { staticClass: "col-5" }, [
        _c("span", { staticClass: "insurance__text-left" }, [
          _vm._v("\n            Срок обращения до:\n          ")
        ])
      ]),
      _c("div", { staticClass: "col" }, [
        _c("span", { staticClass: "insurance__text-right" }, [
          _vm._v("\n            «28» февраля 2023\n          ")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mb-5" }, [
      _c("div", { staticClass: "col-5" }, [
        _c("span", { staticClass: "insurance__text-left" }, [
          _vm._v("\n            Условия получения:\n          ")
        ])
      ]),
      _c("div", { staticClass: "col" }, [
        _c("span", { staticClass: "insurance__text-right" }, [
          _vm._v(
            "\n            Утрата права собственности, вселение третьих лиц\n          "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mb-5" }, [
      _c("div", { staticClass: "col-5" }, [
        _c("span", { staticClass: "insurance__text-left" }, [
          _vm._v("\n            Куда обратиться:\n          ")
        ])
      ]),
      _c("div", { staticClass: "col" }, [
        _c("span", { staticClass: "insurance__text-right" }, [
          _vm._v("\n            help@realtycloud.ru\n          ")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "insurance__footer" }, [
      _c("span", [
        _vm._v("\n        ООО «Реалти Клауд»"),
        _c("br"),
        _vm._v(
          "\n        423800, Россия, город Набережные Челны, ул.Машиностроительная 91, пом.59"
        ),
        _c("br"),
        _vm._v("\n        ОГРН 1181690067932, ИНН 1650367985\n      ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "source-list" }, [
      _c("div", { staticClass: "source-list__head" }, [
        _vm._v("\n        СПИСОК ИСПОЛЬЗОВАННЫХ ИСТОЧНИКОВ "),
        _c("br"),
        _vm._v("\n        ДЛЯ СОСТАВЛЕНИЯ ОТЧЕТА О РИСКАХ\n      ")
      ]),
      _c("ol", { staticClass: "source-list__items" }, [
        _c("li", [
          _vm._v(
            "Гражданский кодекс Российской Федерации : федер. закон от 30 ноября 1994 г. № 51-ФЗ [в ред. от 18.07.2019]"
          )
        ]),
        _c("li", [
          _vm._v(
            "Семейный кодекс Российской Федерации : федер. закон от 29 декабря 1995 г. № 223-ФЗ [в ред. от 02.12.2019]"
          )
        ]),
        _c("li", [
          _vm._v(
            "Жилищный кодекс Российской Федерации : федер. закон от 29 декабря 2004 г. № 188-ФЗ [в ред. от 02.12.2019]"
          )
        ]),
        _c("li", [
          _vm._v(
            "О несостоятельности (банкротстве) : федер. закон от 26 октября 2002 г. № 127-ФЗ [в ред. от 02.12.2019]"
          )
        ]),
        _c("li", [
          _vm._v(
            "Федеральная служба государственной регистрации, кадастра и картографии (Росреестр) URL: https://rosreestr.ru"
          )
        ]),
        _c("li", [
          _vm._v(
            "Публичная кадастровая карта Российской Федерации // Федеральная служба государственной регистрации, кадастра и картографии (Росреестр) URL: https://pkk5.rosreestr.ru"
          )
        ]),
        _c("li", [
          _vm._v(
            "Федеральная служба судебных приставов (ФССП России) URL: https://fssprus.ru/"
          )
        ]),
        _c("li", [
          _vm._v(
            "Федеральная налоговая служба (ФНС России) URL: https://www.nalog.ru"
          )
        ]),
        _c("li", [
          _vm._v(
            "Реестр уведомлений о залоге движимого имущества // Федеральная нотариальная палата России URL: https://www.reestr-zalogov.ru"
          )
        ]),
        _c("li", [
          _vm._v(
            "Официальный сайт Министерства внутренних дел Российской Федерации URL: https://мвд.рф"
          )
        ]),
        _c("li", [
          _vm._v(
            "Единый федеральный реестр сведений о банкротстве URL: https://bankrot.fedresurs.ru"
          )
        ]),
        _c("li", [
          _vm._v(
            "Государственная автоматизированная система Российской Федерации «Правосудие» URL: https://sudrf.ru"
          )
        ]),
        _c("li", [
          _vm._v("Картотека арбитражных дел URL: https://kad.arbitr.ru")
        ]),
        _c("li", [
          _vm._v(
            "Федеральная государственная информационная система «Единый реестр проверок» (ФГИС ЕРП) URL: https://proverki.gov.ru"
          )
        ]),
        _c("li", [
          _vm._v(
            "Федеральная служба по финансовому мониторингу URL: http://www.fedsfm.ru"
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }