var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup__body" },
    [
      _c("app-dialog-close"),
      _c("h5", { staticClass: "text-center mb-3" }, [
        _vm._v("\n    Выставить счёт\n  ")
      ]),
      _c(
        "app-form",
        {
          staticClass: "add-payer-form py-3",
          attrs: {
            "request-handler": _vm.downloadBill,
            model: _vm.model,
            rules: _vm.validationRules
          }
        },
        [
          _c(
            "app-button",
            {
              staticClass: "add-payer-link",
              attrs: { variant: "link", size: "sm" },
              on: { click: _vm.showAddPayerForm }
            },
            [_vm._v("\n      +Добавить\n    ")]
          ),
          _c("div", { staticClass: "pb-2" }, [
            _vm._v("\n      Выберите плательщика:\n    ")
          ]),
          _c(
            "app-form-group",
            {
              staticClass: "rc-form-group required mb-3",
              attrs: { "model-name": "payer" }
            },
            [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.model.payer,
                      expression: "model.payer"
                    }
                  ],
                  staticClass: "custom-select",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.model,
                        "payer",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                _vm._l(_vm.payers, function(payer, i) {
                  return _c("option", { key: i, domProps: { value: payer } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(payer.organisation_name) +
                        ", ИНН " +
                        _vm._s(payer.inn) +
                        "\n        "
                    )
                  ])
                }),
                0
              ),
              _c("label", { class: [{ active: _vm.model.payer }] }, [
                _vm._v("Плательщик")
              ])
            ]
          ),
          _c(
            "app-form-group",
            {
              staticClass: "rc-form-group required mb-3",
              attrs: { "model-name": "amount" }
            },
            [
              _c("app-input", {
                attrs: {
                  type: "number",
                  placeholder: "Введите сумму",
                  label: "Сумма",
                  min: "1",
                  size: "lg"
                },
                model: {
                  value: _vm.model.amount,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "amount", _vm._n($$v))
                  },
                  expression: "model.amount"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "app-button",
                {
                  staticClass: "w-100",
                  attrs: { type: "submit", size: "lg", loading: _vm.loading }
                },
                [_vm._v("\n        Скачать счёт\n      ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }