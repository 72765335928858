


















































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import AppReportCollapseItem from 'shared/components/FizCheck/ReportCollapseItem.vue';
import map from 'lodash/map';
import format from 'date-fns/format';
import { filter } from 'lodash';

@Component({
  components: { AppReportCollapseItem },
  props: {
    result: Array,
    date: String,
  },
})
export default class AppFounderReportDetails extends Vue {
  // props
  result: any[];
  date: string;
  // data
  currentFilter: string = 'all';
  opened: boolean = false;

  get cases() {
    switch (this.currentFilter) {
      case 'active':
        return this.activeCases;
      case 'closed':
        return this.closedCases;
      default:
        return this.sorted;
    }
  }

  get sorted() {
    return map(this.result, (elem: any) => {
      if (elem.individual_data && elem.individual_data.roles) {
        if (elem.org_data.death_date) {
          elem.disabled = true;
          return elem;
        }
        const res = elem.individual_data.roles.filter((role: any) => {
          return role.active === true;
        });
        if (res.length) {
          elem.disabled = false;
        } else {
          elem.disabled = true;
        }
      }
      return elem;
    });
  }

  get counters() {
    const res: any = {};
    this.sorted.forEach((elem: any) => {
      if (elem.individual_data && elem.individual_data.roles) {
        elem.individual_data.roles.forEach((item: any) => {
          if (!res[item.name]) {
            res[item.name] = 0;
          }
          res[item.name] += 1;
        });
      }
    });
    return res;
  }

  get closedCases() {
    return filter(this.sorted, (el: any) => {
      return el.disabled === true;
    });
  }

  get activeCases() {
    return filter(this.sorted, (el: any) => {
      return el.disabled === false;
    });
  }

  getRole(item: any) {
    const arr = item.individual_data.roles.filter((role: any) => {
      return role.active === true;
    });
    if (arr.length) {
      const names = arr.map((elem: any) => elem.name);
      return names.join('/');
    } else {
      const names = item.individual_data.roles.map((elem: any) => elem.name);
      let text = ` (лицо к данной организации на ${format(this.date, 'DD.MM.YYYY')} отношения не имеет)`;
      if (item.org_data.death_date) {
        text = '';
      }
      return names.join('/') + text;
    }
  }
}
