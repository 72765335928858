






































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Validators } from 'shared/utils/validator';
import AppAddPayerForm from 'shared/components/Payers/AddPayerForm.vue';
import { mapState } from 'vuex';
import { AuthTypes } from 'shared/store/modules/auth/types';
import { saveAs } from 'file-saver';
import noop from 'lodash/noop';

@Component({
  popupOptions: {
    name: 'popup_default popup_bill',
    size: 'sm',
  },
  computed: {
    ...mapState('auth', [ 'payers' ]),
  },
  props: {
    sum: {
      type: Number,
      default: 0,
    },
    onSuccess: {
      type: Function,
      default: noop,
    },
  },
})

export default class BillDialog extends Vue {
  // props
  payers: Payer[];
  sum: number;
  onSuccess: any;

  // data
  model: InvoiceModel = {
    payer: null,
    amount: null,
  }

  loading: boolean = true;

  mounted() {
    if (this.sum) {
      this.model.amount = this.sum;
    }
  }

  get isFizcheck() {
    return this.$store.getters[AuthTypes.getters.IS_FIZCHECK];
  }

  // methods
  async downloadBill() {
    this.loading = true;
    try {
      const params = {
        payer_id: this.model.payer.payer_id,
        sum: this.model.amount,
        site: this.isFizcheck ? 'fizcheck' : 'rc',
      };
      const response: Blob = await this.$api.raw.files.GetFileBlob('/invoice', params);
      const fileName = `Счёт для оплаты ${this.model.payer.organisation_name}`;

      await saveAs(response, `${fileName}.pdf`);
    } catch (error) {
      this.$noty.error({ text: 'Произошла ошибка при формировании счёта, пожалуйста, попробуйте еще раз' });
      this.$api.HttpError(error);
      this.loading = false;
    }
    this.onSuccess();
    this.loading = false;
    this.$dialog.close();
  }

  showAddPayerForm() {
    this.$dialog.open({
      component: AppAddPayerForm,
      props: {
        onSubmit: this.addPayer,
      },
    });
  }

  validationRules(): ValidationConfig {
    return {
      payer: [ Validators.required ],
      amount: [ Validators.required, Validators.positiveNumber ],
    };
  }

  async addPayer(payer: Payer) {
    try {
      await this.$store.dispatch(AuthTypes.actions.ADD_PAYER, payer);
      this.$noty.success({ text: 'Плательщик успешно добавлен' });
      this.$dialog.close();
      this.model.payer = this.payers[this.payers.length - 1];
    } catch (error) {
      this.$noty.error({ text: 'Возникла ошибка при добавлении плательщика. Попробуйте ещё раз через несколько минут' });
      console.error(error);
    }
  }

  // lifecycle
  async created() {
    try {
      await this.$store.dispatch(AuthTypes.actions.GET_PAYERS);
      if (this.payers.length) {
        this.model.payer = this.payers[0];
      }
      this.loading = false;
    } catch (error) {
      console.error(error);
      this.$noty.error({ text: 'Не удалось получить плательщиков' });
    }
  }
}
