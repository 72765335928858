var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-statement-item",
    {
      attrs: { "is-disabled": !_vm.hasImages, "is-open": _vm.hasImages },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              _c("i", { staticClass: "fas fa-ruler-combined fa-fw mr-1 icon" }),
              _vm._v(" План объекта\n  ")
            ]
          },
          proxy: true
        },
        {
          key: "status",
          fn: function() {
            return [
              !_vm.payed && _vm.showPayInfo
                ? [
                    _c("client-only", [
                      _c("div", { staticClass: "risk-report-item__status" }, [
                        _c("i", {
                          staticClass: "fas fa-lock mr-2 text-danger"
                        }),
                        _c("span", { staticClass: "d-none d-md-inline" }, [
                          _vm._v("Доступно после заказа")
                        ]),
                        _c("img", {
                          staticClass: "ml-md-4",
                          attrs: { src: "/images/eye.svg" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("icon-click")
                            }
                          }
                        })
                      ])
                    ])
                  ]
                : _vm.hasImages
                ? [
                    _c("i", {
                      staticClass: "fas fa-exclamation-circle text-success"
                    }),
                    _vm._v(" Найдено: " + _vm._s(_vm.images.length) + "\n    ")
                  ]
                : _vm.status !== "done" && !_vm.$isServer && !_vm.opened
                ? [
                    _c("i", { staticClass: "fas fa-info-circle text-info" }),
                    _vm._v(" В работе\n    ")
                  ]
                : [
                    _c("i", {
                      staticClass: "fas fa-check-circle text-success"
                    }),
                    _vm._v(" Не найдено\n    ")
                  ]
            ]
          },
          proxy: true
        }
      ])
    },
    [
      [
        _vm.hasImages
          ? [
              _vm._t(
                "carousel",
                _vm._l(_vm.images, function(elem, i) {
                  return _c("div", { key: i }, [
                    _c("img", {
                      staticClass: "pointer-events-none",
                      attrs: { src: elem, width: "100%" }
                    })
                  ])
                })
              )
            ]
          : _vm._e()
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }