import Vue from 'vue';
import Component from 'vue-class-component';
import ClipboardJS from 'clipboard';

import { AuthTypes } from 'shared/store/modules/auth/types';
import store from '@/store';
import AdminManualAttachOrderItem from '../components/AdminManualAttachOrderItem.vue';
import AdminManualAttachOrder from '../components/AdminManualAttachOrder.vue';
import AddResponsibleToUserDialog from '../components/AddResponsibleToUserDialog.vue';
import AdminChooseRoleDialog from '../components/AdminChooseRoleDialog.vue';
import AdminChangeEmailDialog from '../components/AdminChangeEmailDialog.vue';
import AdminUserSettingsDialog from '../components/AdminUserSettingsDialog.vue';
import AdminChangePhoneDialog from '../components/AdminChangePhoneDialog.vue';
import { Role, RoleNames } from 'shared/utils/constants';

let clipboard: ClipboardJS;
@Component({
  props: {
    userID: String,
    orderID: String,
  },

  async beforeRouteEnter(to, from, next) {
    if (!store.getters[AuthTypes.getters.IS_ROLE](Role.PartnerRisk)) {
      await store.dispatch('admin/getPersonInfo', to.params.userID);
    }

    next();
  },
})
export default class AdminUserInfo extends Vue {
  // props
  userID!: string;
  orderID!: string;

  // data
  btnLoading: boolean = false;

  // user: any = null;
  roles: any = { ...RoleNames };
  userResponsible: User = null;

  // computed
  get isAdmin(): boolean {
    return this.$store.getters[AuthTypes.getters.IS_ADMIN];
  }

  get isManager(): boolean {
    return this.$store.getters[AuthTypes.getters.IS_ROLE](Role.Manager);
  }

  get user(): User {
    return this.$store.state.admin.user;
  }

  get isPartnerRisk(): boolean {
    return this.$store.getters[AuthTypes.getters.IS_ROLE](Role.PartnerRisk);
  }

  created() {
    this.getResponsibleUser();
  }

  async mounted() {
    clipboard = new ClipboardJS('[data-clipboard-text]');
    clipboard.on('success', (event: ClipboardJS.Event) => {
      const title = event.trigger.getAttribute('data-title') || 'Transaction ID';

      this.$noty.success({
        text: `<div class="small">${title} скопирован <br> ${event.text}</div>`,
      });
    });
  }

  beforeDestroy() {
    if (clipboard) {
      clipboard.destroy();
      clipboard = null;
    }
  }

  // methods
  openDialog(dialogName: string) {
    let component;
    if (dialogName === 'order-item') {
      component = AdminManualAttachOrderItem;
    } else {
      component = AdminManualAttachOrder;
    }
    this.$dialog.open({ component, props: { userId: this.userID } });
  }

  openAddResponsibleDialog() {
    this.$dialog.open({
      component: AddResponsibleToUserDialog,
      props: {
        userId: this.userID,
        onResponsibleUserUpdated: this.getResponsibleUser,
      },
    });
  }

  openChooseRoleDialog() {
    this.$dialog.open({
      component: AdminChooseRoleDialog,
      props: {
        userId: this.userID,
        onChoose: (newRole: number) => {
          this.user.person_role = newRole;
        },
      },
    });
  }

  openChangeEmailDialog() {
    this.$dialog.open({
      component: AdminChangeEmailDialog,
      props: {
        userId: this.userID,
        submit: (email: string) => {
          this.user.email = email;
        },
      },
    });
  }

  openUserSettingsDialog() {
    this.$dialog.open({
      component: AdminUserSettingsDialog,
      props: {
        userId: this.userID,
      },
    });
  }

  openChangePhoneDialog() {
    this.$dialog.open({
      component: AdminChangePhoneDialog,
      props: {
        userId: this.userID,
        submit: (phone: string) => {
          this.user.phone = phone;
        },
      },
    });
  }

  async getResponsibleUser() {
    const userResponsible = await this.$api.admin.GetResponsibleUser(this.userID);
    this.userResponsible = userResponsible[0];
  }

  async removeResponsible() {
    try {
      const response = await this.$api.admin.RemoveResponsible({ responsible_person_id: this.userResponsible.id, controlled_person_id: this.userID });
      if (response.status === 'removed') {
        this.$noty.success({ text: 'Успешно удалён' });
        this.getResponsibleUser();
      }
    } catch (error) {
      this.$noty.error({ text: 'Ошибка при удалении' });
    }
  }

  async recoverAccount() {
    this.btnLoading = true;
    try {
      const response = await this.$api.admin.RecoverAccount({ id: this.userID });

      if (response.status === 'ok') {
        this.$noty.success({ text: 'Аккаунт восстановлен' });
        this.btnLoading = false;
        await store.dispatch('admin/getPersonInfo', this.userID);
      }
    } catch (error) {
      this.$noty.error({ text: 'Ошибка при восстановлении аккаунта' });
      console.error(error);
    }
    this.btnLoading = false;
  }

  getWhatsAppLink(phone: string): string {
    const phoneWithoutPlus: string = phone.slice(1);
    return `https://api.whatsapp.com/send/?phone=${phoneWithoutPlus}`;
  }
}
