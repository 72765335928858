var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-report-item",
    {
      attrs: {
        title: "Сведения о долгах у судебных приставов",
        icon: "file-invoice",
        status: _vm.status,
        "missing-requirements": _vm.missingRequirements,
        images: _vm.images,
        opened: _vm.resultStatus === "found",
        color: _vm.map.color,
        source: {
          domain: "fssprus.ru",
          link: "http://fssprus.ru/iss/ip"
        }
      },
      scopedSlots: _vm._u(
        [
          _vm.resultStatus === "found"
            ? {
                key: "extra-content",
                fn: function() {
                  return [
                    _c("div", { staticClass: "report-item-result__graph" }, [
                      _c(
                        "div",
                        { staticClass: "report-item-result__graph-filter" },
                        [
                          _c("div", [
                            _c("h4", [
                              _vm._v("\n            Совпадения:\n          ")
                            ])
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "report-item-result__graph-filter-radio"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__graph-filter-radio-btn",
                                  attrs: {
                                    active: _vm.currentFilter === "main",
                                    disabled: !_vm.counters.cases
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.currentFilter = "main"
                                    }
                                  }
                                },
                                [
                                  _vm.isPhysical
                                    ? [
                                        _vm._v(
                                          "\n              по ФИО + Д.Р.\n            "
                                        )
                                      ]
                                    : [
                                        _vm._v(
                                          "\n              по Названию организации\n            "
                                        )
                                      ],
                                  _vm.fsspTaskFailed
                                    ? [
                                        _c("i", {
                                          staticClass:
                                            "fas fa-spinner fa-sm fa-spin text-info ml-2"
                                        })
                                      ]
                                    : [
                                        _vm._v(
                                          "\n              (" +
                                            _vm._s(_vm.counters.cases) +
                                            ")\n            "
                                        )
                                      ]
                                ],
                                2
                              ),
                              _vm.gosuslugiBillsResult
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__graph-filter-radio-btn",
                                      attrs: {
                                        active:
                                          _vm.currentFilter === "gosuslugi",
                                        disabled: !_vm.counters.casesGos
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.currentFilter = "gosuslugi"
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n            по документам (" +
                                          _vm._s(_vm.counters.casesGos) +
                                          ")\n          "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      ),
                      _c("div", {
                        staticClass: "report-item-result__graph-divider"
                      }),
                      _c(
                        "div",
                        { staticClass: "report-item-result__graph-counters" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "report-item-result__graph-counters-elem"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__graph-counters-elem-key"
                                },
                                [
                                  _vm._v(
                                    "\n            Исполнительные производства\n          "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__graph-counters-elem-result"
                                },
                                [
                                  _vm.currentFilter === "main"
                                    ? [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(_vm.counters.cases) +
                                            "\n            "
                                        )
                                      ]
                                    : [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(_vm.counters.casesGos) +
                                            "\n            "
                                        )
                                      ]
                                ],
                                2
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "report-item-result__graph-counters-elem"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__graph-counters-elem-key"
                                },
                                [
                                  _vm._v(
                                    "\n            Общая сумма\n          "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__graph-counters-elem-result"
                                },
                                [
                                  _vm.currentFilter === "main"
                                    ? [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.counters.sum,
                                                { precision: 2 }
                                              )
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    : [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.counters.sumGos,
                                                { precision: 2 }
                                              )
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                ],
                                2
                              )
                            ]
                          )
                        ]
                      )
                    ]),
                    _vm.counters.cases + _vm.counters.casesGos > 1
                      ? _c(
                          "div",
                          { staticClass: "text-right report-open-all mt-3" },
                          [
                            !_vm.$isServer
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "link",
                                    on: {
                                      click: function($event) {
                                        _vm.opened = !_vm.opened
                                      }
                                    }
                                  },
                                  [
                                    !_vm.opened
                                      ? [
                                          _vm._v(
                                            "\n          Раскрыть все\n        "
                                          )
                                        ]
                                      : [
                                          _vm._v(
                                            "\n          Скрыть все\n        "
                                          )
                                        ]
                                  ],
                                  2
                                )
                              : _vm._e()
                          ]
                        )
                      : _vm._e(),
                    _vm.gosuslugiBillsResult.length &&
                    _vm.currentFilter === "gosuslugi"
                      ? _c("app-fssp-gosuslugi-details", {
                          attrs: {
                            opened: _vm.opened || _vm.isPdf,
                            result: _vm.$isServer
                              ? _vm.slicedCases.gosuslugi
                              : _vm.gosuslugiBillsResult
                          }
                        })
                      : _vm._e(),
                    _vm.currentFilter === "main"
                      ? _c("app-fssp-details", {
                          attrs: {
                            opened: _vm.opened || _vm.isPdf,
                            result: _vm.$isServer
                              ? _vm.slicedCases.main
                              : _vm.result
                          }
                        })
                      : _vm._e(),
                    _vm.slicedCases.count <
                      _vm.counters.cases + _vm.counters.casesGos &&
                    _vm.$isServer
                      ? _c(
                          "div",
                          { staticClass: "rc-modern-info-panel mt-3" },
                          [
                            _c(
                              "div",
                              { staticClass: "rc-modern-info-panel__wrapper" },
                              [
                                _c("div", [_c("app-warning-img")], 1),
                                _c("div", [
                                  _vm._v(
                                    "\n          Отображено " +
                                      _vm._s(_vm.slicedCases.count) +
                                      " дел из " +
                                      _vm._s(
                                        _vm.counters.cases +
                                          _vm.counters.casesGos
                                      ) +
                                      ", полная информация доступна в веб версии отчета.\n        "
                                  )
                                ])
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      _c(
        "div",
        { staticClass: "report-item-modern__subtitle" },
        [
          _vm.resultStatus === "found"
            ? [_vm._v("\n      Найдены исполнительные производства\n      ")]
            : _vm.resultStatus === "not found"
            ? [
                _vm._v(
                  "\n      В базе данных ФССП исполнительные производства не числится\n    "
                )
              ]
            : _vm.orderIsDone
            ? [
                _c("span", { staticClass: "text-warning" }, [
                  _vm._v(" Недостаточно данных ")
                ])
              ]
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }