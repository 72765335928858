







































































































import Vue from 'vue';
import Component from 'vue-class-component';
import get from 'lodash/get';

@Component({
  components: {},
  props: {
    result: Array,
    name: String,
  },
})
export default class AppSocialMediaReportElem extends Vue {
  // props
  result: any[];
  name: string;

  getField(elem: any, name: string) {
    const recordFields = get(elem, 'record.0.fields');
    if (recordFields) {
      const field = recordFields.find((field: any) => {
        return field.field_name === name;
      });
      return field ? field.field_value : undefined;
    }
  }
}
