



























































































































































































































































































































































































































































































import Component, { mixins } from 'vue-class-component';
import get from 'lodash/get';

import StatementEgrnMixin from 'shared/mixins/statements/statementEgrn.mixin';
import AppStatementTableRow from 'shared/components/EgrnObject/StatementTableRow.vue';
import { OBJECT_BY_TYPE } from 'shared/utils/constants/object-types';
import AppEcpSvg from 'shared/components/Static/ecp-print.vue';

@Component({
  components: { AppStatementTableRow, AppEcpSvg },

  props: {
    landCadInfo: Object,
    info: Object,
    objectKey: String,
    statements: Object,
    riskReport: Object,
  },
})
export default class EgrnObjectReport extends mixins(StatementEgrnMixin) {
  landCadInfo: any;
  info: any;
  objectKey: any;
  statements: any;
  riskReport: any;
  get = get;

  get objectPlan() {
    if (this.egrnTask && this.egrnTask.meta) {
      return this.egrnTask.meta.images;
    }
    return [];
  }

  get egrnTask() {
    if (this.riskReport && this.riskReport.tasks_parse_xml) {
      return this.riskReport.tasks_parse_xml.find((elem: any) => {
        if (elem) {
          return elem.task_type === 'download_service_egrn_object_parse';
        }
      });
    }
    return null;
  }

  objectType(value: any) {
    return OBJECT_BY_TYPE[value];
  }
}
