var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.report.owner.phone && _vm.extendedReportEnabled
    ? _c(
        "app-report-item",
        {
          attrs: {
            title: "Информация по телефону" + _vm.report.owner.phone,
            icon: "phone",
            status: _vm.status,
            images: _vm.images,
            color: _vm.map.color,
            "missing-requirements": _vm.missingRequirements,
            opened: !!_vm.ulixesItems
          },
          scopedSlots: _vm._u(
            [
              _vm.resultStatus === "found"
                ? {
                    key: "extra-content",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "report-item-result report-item-result__result-wrapper"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "report-item-result__result extended-report-alt-divider"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "report-item-result__content-list"
                                  },
                                  _vm._l(_vm.ulixesItems, function(
                                    value,
                                    name
                                  ) {
                                    return _c(
                                      "div",
                                      {
                                        key: name,
                                        staticClass:
                                          "report-item-result__content-list-elem"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-title"
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(name) +
                                                "\n            "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-result"
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(value) +
                                                "\n            "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  }),
                                  0
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    },
                    proxy: true
                  }
                : null
            ],
            null,
            true
          )
        },
        [
          _vm.task
            ? _c(
                "div",
                { staticClass: "report-item-modern__subtitle-wrapper" },
                [
                  _vm.resultStatus === "not found"
                    ? [
                        _c(
                          "div",
                          { staticClass: "report-item-modern__subtitle" },
                          [
                            _c("div", {
                              staticClass:
                                "report-item-modern__dot report-item-modern__color--success"
                            }),
                            _c("div", [
                              _vm._v(
                                "\n          Информация не найдена\n        "
                              )
                            ])
                          ]
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }