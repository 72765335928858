var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.taskResult || _vm.showSteps
      ? _c(
          "div",
          { staticClass: "extended-report", attrs: { id: "extended-report" } },
          [
            _c("div", { staticClass: "modern-panel" }, [
              _c("div", { staticClass: "modern-panel__header" }, [
                _c("div", { staticClass: "extended-report-panel-header" }, [
                  _c("div", [
                    _vm._v(
                      "\n            Расширенный отчет на основе ИИ\n          "
                    )
                  ]),
                  _vm.orderIsDone && _vm.ulixesAlt.length && !_vm.$isServer
                    ? _c(
                        "div",
                        [
                          _c(
                            "scrollactive",
                            { attrs: { offset: 500, "scroll-offset": 500 } },
                            [
                              _c(
                                "a",
                                { attrs: { href: "#ai-conclusions" } },
                                [
                                  _c(
                                    "app-button",
                                    {
                                      attrs: {
                                        variant: "modern-purple",
                                        size: "lg",
                                        block: ""
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "mr-2",
                                        attrs: {
                                          src: "/rc-modern/Ai-button.svg"
                                        }
                                      }),
                                      _vm._v(
                                        "\n                  Выводы ИИ\n                "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.statusAnalysis && _vm.ulixesAlt.length
                    ? _c(
                        "div",
                        [
                          _c(
                            "scrollactive",
                            { attrs: { offset: 500, "scroll-offset": 500 } },
                            [
                              _c(
                                "a",
                                { attrs: { href: "#ulixes-report" } },
                                [
                                  _c(
                                    "app-button",
                                    {
                                      attrs: {
                                        variant: "modern-outline",
                                        size: "lg"
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "mr-2",
                                        staticStyle: {
                                          filter:
                                            "invert(44%) sepia(80%) saturate(5601%) hue-rotate(214deg) brightness(102%) contrast(87%)"
                                        },
                                        attrs: {
                                          src: "/rc-modern/Ai-button.svg"
                                        }
                                      }),
                                      _vm._v(
                                        "\n                  Смотреть неформатированный отчет\n                "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ]),
              _vm.orderIsDone
                ? _c(
                    "div",
                    { staticClass: "modern-panel__items" },
                    [
                      _vm.ulixesAlt.length
                        ? [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "modern-panel__content order-1 order-md-0"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "extended-report__title" },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "fas fa-check-circle extended-report__check"
                                    }),
                                    _vm._v(
                                      "\n              Отчет полностью готов. Найдены данные в " +
                                        _vm._s(_vm.ulixesAlt.length) +
                                        " источниках:\n            "
                                    )
                                  ]
                                )
                              ]
                            ),
                            !_vm.$isServer
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "fizcheck-form-switch order-0 order-md-1"
                                  },
                                  [
                                    _vm._m(0),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "fizcheck-form-switch__button"
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.showExtendedReportGrouped,
                                              expression:
                                                "showExtendedReportGrouped"
                                            }
                                          ],
                                          staticClass:
                                            "ios8-switch ios8-switch-lg",
                                          attrs: {
                                            type: "checkbox",
                                            id: "checkbox-1"
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.showExtendedReportGrouped
                                            )
                                              ? _vm._i(
                                                  _vm.showExtendedReportGrouped,
                                                  null
                                                ) > -1
                                              : _vm.showExtendedReportGrouped
                                          },
                                          on: {
                                            change: function($event) {
                                              var $$a =
                                                  _vm.showExtendedReportGrouped,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    (_vm.showExtendedReportGrouped = $$a.concat(
                                                      [$$v]
                                                    ))
                                                } else {
                                                  $$i > -1 &&
                                                    (_vm.showExtendedReportGrouped = $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                                }
                                              } else {
                                                _vm.showExtendedReportGrouped = $$c
                                              }
                                            }
                                          }
                                        }),
                                        _c("label", {
                                          attrs: { for: "checkbox-1" }
                                        })
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        : _vm._t("refund")
                    ],
                    2
                  )
                : _vm._e(),
              (_vm.taskResult && !_vm.orderIsDone) ||
              (_vm.showSteps && !_vm.orderIsDone)
                ? _c(
                    "div",
                    { staticClass: "modern-panel__wrapper" },
                    [
                      _c("div", { staticClass: "extended-report-steps" }, [
                        _vm._m(1),
                        _c("div", {
                          staticClass: "extended-report-steps__line"
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "extended-report-steps__step",
                            class: { active: !_vm.statusCollection }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "extended-report-steps__step-circle"
                              },
                              [_vm._v("\n              2\n            ")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "extended-report-steps__step-text"
                              },
                              [
                                _vm._v(
                                  "\n              Анализ данных\n            "
                                )
                              ]
                            )
                          ]
                        ),
                        _c("div", {
                          staticClass: "extended-report-steps__line"
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "extended-report-steps__step",
                            class: {
                              active: _vm.statusGeneration || _vm.orderIsDone
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "extended-report-steps__step-circle"
                              },
                              [_vm._v("\n              3\n            ")]
                            ),
                            _vm._m(2)
                          ]
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "progress extended-report-progress" },
                        [
                          _c("div", {
                            staticClass: "progress-bar",
                            style: { width: _vm.progress + "%" }
                          })
                        ]
                      ),
                      _vm.statusCollection
                        ? _c(
                            "div",
                            { staticClass: "extended-report-steps__status" },
                            [
                              _c("img", {
                                attrs: {
                                  src:
                                    "/rc-modern/Ai-Upscale-Spark--Streamline-Core.svg.svg"
                                }
                              }),
                              _vm._v(
                                "\n          Сбор данных начат. Процесс может занять немного времени.\n        "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.statusAnalysis ? [_vm._m(3), _vm._m(4)] : _vm._e(),
                      _vm.statusGeneration
                        ? [_vm._m(5), _vm._m(6), _vm._m(7)]
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e(),
              _vm.orderIsDone &&
              _vm.taskResult.users &&
              _vm.taskResult.users.length > 1 &&
              _vm.showExtendedReportGrouped
                ? _c("div", { staticClass: "modern-panel__wrapper" }, [
                    _c(
                      "div",
                      { staticClass: "extended-report-user-wrapper" },
                      _vm._l(_vm.taskResult.users, function(user, i) {
                        return _c(
                          "div",
                          {
                            key: i,
                            staticClass: "extended-report-user",
                            class: { active: i === _vm.ulixesUser },
                            on: {
                              click: function($event) {
                                return _vm.changeUser(i)
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "extended-report-user__title" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(user.fio) +
                                    "\n            "
                                )
                              ]
                            ),
                            user.list_inn && user.list_inn.length
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "extended-report-user__subtitle"
                                  },
                                  [
                                    _vm._v(
                                      "\n              ИНН: " +
                                        _vm._s(user.list_inn[0]) +
                                        "\n            "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "extended-report-user__subtitle" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(user.birthday) +
                                    "\n            "
                                )
                              ]
                            )
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._m(8)
                  ])
                : _vm._e()
            ]),
            _vm.haveDocs && _vm.showExtendedReportGrouped
              ? _c("app-extended-report-docs", { attrs: { user: _vm.user } })
              : _vm._e(),
            (_vm.adresses || _vm.emails || _vm.phones) &&
            _vm.showExtendedReportGrouped
              ? _c("div", { staticClass: "extended-report-panel" }, [
                  _vm._m(9),
                  _c(
                    "div",
                    { staticClass: "extended-report-panel-personal-table" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "extended-report-panel-personal-table__elem flex-grow-1"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "extended-report-panel-personal-table__elem-header"
                            },
                            [_vm._v("\n            Адрес\n          ")]
                          ),
                          _c("div", {
                            staticClass:
                              "extended-report-panel-personal-table__divider extended-report-panel-personal-table__divider--mr"
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "extended-report-panel-personal-table__elem-data"
                            },
                            [
                              _vm.adresses && _vm.adresses.length
                                ? [
                                    _vm._l(_vm.adresses, function(elem) {
                                      return _c(
                                        "div",
                                        {
                                          key: elem.value,
                                          staticClass: "d-flex"
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "overflow-auto" },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(elem.value) +
                                                  "\n                "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "extended-report-panel-personal-table__count"
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(elem.amount) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    }),
                                    _vm.user.list_adresses.length > 1 &&
                                    !_vm.$isServer
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "link",
                                            on: {
                                              click: function($event) {
                                                _vm.showAllAdresses = !_vm.showAllAdresses
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                Показать еще (" +
                                                _vm._s(
                                                  _vm.user.list_adresses
                                                    .length - 1
                                                ) +
                                                ")\n              "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                : _c("div", [
                                    _vm._v(
                                      "\n              Не найден\n            "
                                    )
                                  ])
                            ],
                            2
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "extended-report-panel-personal-table__elem"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "extended-report-panel-personal-table__elem-header"
                            },
                            [_vm._v("\n            Email\n          ")]
                          ),
                          _c("div", {
                            staticClass:
                              "extended-report-panel-personal-table__divider extended-report-panel-personal-table__divider--mr"
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "extended-report-panel-personal-table__elem-data"
                            },
                            [
                              _vm.emails && _vm.emails.length
                                ? [
                                    _vm._l(_vm.emails, function(elem) {
                                      return _c(
                                        "div",
                                        {
                                          key: elem.value,
                                          staticClass: "d-flex"
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "overflow-auto" },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(elem.value) +
                                                  "\n                "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "extended-report-panel-personal-table__count"
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(elem.amount) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    }),
                                    _vm.user.list_emails.length > 1
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "link",
                                            on: {
                                              click: function($event) {
                                                _vm.showAllEmails = !_vm.showAllEmails
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                Показать еще (" +
                                                _vm._s(
                                                  _vm.user.list_emails.length -
                                                    1
                                                ) +
                                                ")\n              "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                : _c("div", [
                                    _vm._v(
                                      "\n              Не найден\n            "
                                    )
                                  ])
                            ],
                            2
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "extended-report-panel-personal-table__elem"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "extended-report-panel-personal-table__elem-header"
                            },
                            [_vm._v("\n            Телефон\n          ")]
                          ),
                          _c("div", {
                            staticClass:
                              "extended-report-panel-personal-table__divider"
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "extended-report-panel-personal-table__elem-data"
                            },
                            [
                              _vm.phones && _vm.phones.length
                                ? [
                                    _vm._l(_vm.phones, function(elem) {
                                      return _c(
                                        "div",
                                        {
                                          key: elem.value,
                                          staticClass: "d-flex"
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "overflow-auto" },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(elem.value) +
                                                  "\n                "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "extended-report-panel-personal-table__count"
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(elem.amount) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    }),
                                    _vm.user.list_phones.length > 1
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "link",
                                            on: {
                                              click: function($event) {
                                                _vm.showAllPhones = !_vm.showAllPhones
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                Показать еще (" +
                                                _vm._s(
                                                  _vm.user.list_phones.length -
                                                    1
                                                ) +
                                                ")\n              "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                : _c("div", [
                                    _vm._v(
                                      "\n              Не найден\n            "
                                    )
                                  ])
                            ],
                            2
                          )
                        ]
                      )
                    ]
                  )
                ])
              : _vm._e(),
            _c("div", { attrs: { id: "ulixes-report" } }),
            _vm.ulixes && _vm.ulixes.length
              ? [
                  !_vm.showExtendedReportGrouped
                    ? _c("app-fizcheck-expert-summary", {
                        attrs: {
                          "current-filter": _vm.currentFilter,
                          "data-filter": _vm.dataFilter,
                          "person-filter": _vm.personFilter,
                          "all-data": _vm.allData,
                          users: _vm.users,
                          "finded-users": _vm.findedUsers,
                          "finded-users-data": _vm.findedUsersData,
                          "related-data": _vm.relatedDataInFilteredUlixes
                        },
                        on: {
                          filterSelect: _vm.onFilterSelect,
                          personSelect: _vm.onPersonSelect,
                          dataSelect: _vm.onDataSelect
                        }
                      })
                    : _vm._e(),
                  _c("app-fizcheck-expert-conclusions", {
                    attrs: { result: _vm.taskResult.ai, user: _vm.user }
                  }),
                  _vm.showExtendedReportGrouped
                    ? _vm._l(_vm.ulixes, function(ulixesItem) {
                        return _c(
                          "div",
                          { key: ulixesItem.name },
                          [
                            ulixesItem.name === "ПРЕСТУПЛЕНИЯ И НАРУШЕНИЯ"
                              ? _c("app-extended-criminal-report", {
                                  attrs: {
                                    opened: _vm.openAllExtended,
                                    result: ulixesItem
                                  }
                                })
                              : ulixesItem.name === "ИСТОРИЯ ФССП"
                              ? _c("app-extended-fssp-report", {
                                  attrs: {
                                    opened: _vm.openAllExtended,
                                    result: ulixesItem
                                  }
                                })
                              : ulixesItem.name ===
                                  "УЧРЕДИТЕЛЬ - РУКОВОДИТЕЛЬ ОРГАНИЗАЦИИ" &&
                                (_vm.companies && _vm.companies.length)
                              ? _c("app-extended-report-companies", {
                                  attrs: {
                                    "item-result": ulixesItem,
                                    status: _vm.ulixes.order_status,
                                    user: _vm.user
                                  }
                                })
                              : _c("app-extended-report", {
                                  attrs: {
                                    opened: _vm.openAllExtended,
                                    result: ulixesItem
                                  }
                                })
                          ],
                          1
                        )
                      })
                    : _vm._l(_vm.ulixesAltFiltered, function(ulixesItem, i) {
                        return _c(
                          "div",
                          { key: i + 2.3 },
                          [
                            _c("app-extended-report-alt", {
                              attrs: {
                                id: ulixesItem["ИСТОЧНИК"],
                                opened: _vm.openAllExtended,
                                result: ulixesItem
                              }
                            })
                          ],
                          1
                        )
                      })
                ]
              : _vm._e(),
            _vm.orderIsDone && _vm.ulixesAlt.length
              ? _c(
                  "div",
                  {
                    staticClass: "extended-report-panel",
                    attrs: { id: "ai-conclusions" }
                  },
                  [
                    _vm._m(10),
                    _vm._l(_vm.taskResult.results, function(conclusion, index) {
                      return _c("app-risk-conclusion-item", {
                        key: index,
                        attrs: {
                          conclusion: { color_level: "", text: conclusion }
                        }
                      })
                    })
                  ],
                  2
                )
              : _vm._e()
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "fizcheck-form-switch__text" }, [
      _c("span", [_vm._v("Форматированный отчет")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "extended-report-steps__step active" }, [
      _c("div", { staticClass: "extended-report-steps__step-circle" }, [
        _vm._v("\n              1\n            ")
      ]),
      _c("div", { staticClass: "extended-report-steps__step-text" }, [
        _vm._v("\n              Сбор данных\n            ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "extended-report-steps__step-text" }, [
      _vm._v("\n              Формирование "),
      _c("br", { staticClass: "d-none d-md-block" }),
      _vm._v(" выводов\n            ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "extended-report-steps__stauts" }, [
      _c("img", { attrs: { src: "/rc-modern/icon-analysis-success.svg" } }),
      _vm._v(
        "\n            Данные собраны. Можно перейти к просмотру, но рекомендуем дождаться анализа данных для полноты картины.\n          "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "extended-report-steps__stauts" }, [
      _c("img", {
        attrs: { src: "/rc-modern/Ai-Redo-Spark--Streamline-Core.svg.svg" }
      }),
      _vm._v(
        "\n            Анализ данных в процессе. Проверяем соответствие и документы, форматируем отчет для удобного просмотра.\n          "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "extended-report-steps__stauts" }, [
      _c("img", { attrs: { src: "/rc-modern/icon-analysis-success.svg" } }),
      _vm._v(
        "\n            Данные собраны. Можно перейти к просмотру, но рекомендуем дождаться анализа данных для полноты картины.\n          "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "extended-report-steps__stauts" }, [
      _c("img", { attrs: { src: "/rc-modern/icon-analysis-success.svg" } }),
      _vm._v(
        "\n            Анализ завершён. Теперь вы можете ознакомиться с полным отчетом.\n          "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "extended-report-steps__stauts" }, [
      _c("img", {
        attrs: {
          src:
            "/rc-modern/Ai-Generate-Portrait-Image-Spark--Streamline-Core.svg.svg"
        }
      }),
      _vm._v("\n            Формируем выводы по лицу...\n          ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "extended-report-message" }, [
      _c("div", [
        _c("img", { attrs: { src: "/rc-modern/Sd card alert.svg" } })
      ]),
      _c("div", [
        _vm._v(
          "\n            Найдено несколько лиц с похожими исходными данными. Информация о каждом лице отдельно сгруппирована. В первом профиле отображается больше информации, соответствующая исходным данным.\n          "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "extended-report-panel__header" }, [
      _c("div", { staticClass: "extended-report-panel__header-icon" }, [
        _c("i", { staticClass: "fas fa-user" })
      ]),
      _c("div", { staticClass: "extended-report-panel__header-text" }, [
        _c("div", { staticClass: "extended-report-panel__title" }, [
          _vm._v("\n            Личные данные\n          ")
        ]),
        _c("div", { staticClass: "report-item-modern__subtitle" }, [
          _c("div", {}, [
            _vm._v(
              "\n              Отмеченные зеленым данные, вероятно, являются самыми недавними или наиболее актуальными.\n              Рядом указано частота использования в найденных данных.\n            "
            )
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "extended-report-panel__header" }, [
      _c("div", { staticClass: "extended-report-panel__header-icon" }, [
        _c("i", { staticClass: "fas fa-user" })
      ]),
      _c("div", { staticClass: "extended-report-panel__header-text" }, [
        _c("div", { staticClass: "extended-report-panel__title" }, [
          _vm._v("\n            Выводы ИИ\n          ")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }