var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "extended-report-panel gap-12" },
    [
      _vm._m(0),
      _vm.result && _vm.result.length
        ? [
            _c(
              "div",
              [
                _vm._v(
                  "\n      На основании предоставленной информации,\n      было проведено комплексное исследование личности\n      и истории\n      "
                ),
                _vm.user && _vm.user.birthday
                  ? [_vm._v("\n        " + _vm._s(_vm.user.fio) + "\n      ")]
                  : _vm._e(),
                _vm.user && _vm.user.birthday
                  ? [
                      _vm._v(
                        "\n        " + _vm._s(_vm.user.birthday) + "\n      "
                      )
                    ]
                  : _vm._e()
              ],
              2
            ),
            _c("div", { staticClass: "extended-report-panel__divider" }),
            _c("div", [
              _c(
                "div",
                { staticClass: "report-item-result__graph-filter-radio" },
                _vm._l(_vm.result, function(item) {
                  return _c(
                    "div",
                    {
                      key: item.group_name,
                      staticClass: "report-item-result__graph-filter-radio-btn",
                      attrs: { active: _vm.currentFilter === item.group_name },
                      on: {
                        click: function($event) {
                          return _vm.filterSelect(item.group_name)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(item.group_name) + "\n        "
                      )
                    ]
                  )
                }),
                0
              ),
              _vm.selectedResult
                ? _c("div", { staticClass: "extended-report-summary" }, [
                    _c("span", {
                      staticStyle: { "white-space": "pre-line" },
                      domProps: {
                        innerHTML: _vm._s(_vm.selectedResult.result_ai)
                      }
                    })
                  ])
                : _vm._e()
            ])
          ]
        : _c("div", [
            _vm._v(
              "\n    Вы уже можете просматривать данные ниже,\n    выводы по категориям еще формируются и скоро будут доступны.\n  "
            )
          ])
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "extended-report-panel__header" }, [
      _c("div", { staticClass: "extended-report-panel__header-icon" }, [
        _c("i", { staticClass: "fas fa-hdd" })
      ]),
      _c("div", { staticClass: "extended-report-panel__header-text" }, [
        _c("div", { staticClass: "extended-report-panel__title" }, [
          _vm._v("\n        Выводы\n      ")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }